import React from 'react';
import {  Link } from "react-router-dom";

export default function index() {
  return (
    <>
        
<div>

 
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* banner blank space area */}
  <div className="rts-banner-area rts-banner-one">
    <div className="swiper mySwiper banner-one">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span>Welcome!</span> Start Growing Your Business Today
            </p>
            <h1 className="title ">Creative<span> Web Designing</span> <br />
              Services</h1>
            <p className="disc banner-para">
              Unique and Eye-Catching Website Designs that Inspire, Engage and Deliver Results. 
            </p>
            <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/web-designing`}>Read More</Link>
            <img className="shape-img one" src="assets/images/banner/shape/01.png" alt="banner_business" />
          </div>
          {/* banner single content end */}
        </div>
        <div className="swiper-slide two">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span>Welcome!</span> Start Growing Your Business Today
            </p>
            <h1 className="title ">Best<span>Web Development</span> <br /> Services</h1>
            <p className="disc banner-para">
              Get well-planned, mobile-optimized, and secure websites by the best web  Development Company.
            </p>
            <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/web-development`}>Read More</Link>
             <img className="shape-img one" src="assets/images/banner/shape/01.png" alt="banner_business" />
          </div>
          {/* banner single content end */}
        </div>
        <div className="swiper-slide three">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span>Welcome!</span> Start Growing Your Business Today
            </p>
            <h1 className="title ">Best <span> Digital Marketing</span> Services - SEO | SMM</h1>
            <p className="disc banner-para">
              Intensify Online Presence with our unique Digital Marketing Services. Generate the leads &amp; also enhance the branding of your company. SEO is helping to increase your online presence.
            </p>
            <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/digital-marketing`}>Read More</Link>
            <img className="shape-img one" src="assets/images/banner/shape/01.png" alt="banner_business" />
          </div>
          {/* banner single content end */}
        </div>
        <div className="swiper-slide four">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span>Welcome!</span> Start Growing Your Business Today
            </p>
            <h1 className="title ">Best <span> Graphic Designing</span> Services</h1>
            <p className="disc banner-para">
              Get your Business on top with the best Graphic Design Services. Customer centered, client driven, making site arrangements that convey substantial business results.
            </p>
            <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/graphic-designing.html`}>Read More</Link>
            <img className="shape-img one" src="assets/images/banner/shape/01.png" alt="banner_business" />
          </div>
          {/* banner single content end */}
        </div>
        <div className="swiper-slide five">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span>Welcome!</span> Start Growing Your Business Today
            </p>
            <h1 className="title ">Best <span> E-Commerce</span> Web Development Services.</h1>
            <p className="disc banner-para">
              Creating Value for Online Stores and Business Through Innovative E-commerce Solutions.
            </p>
            <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/e-commerce-solutions`}>Read More</Link>
            <img className="shape-img one" src="assets/images/banner/shape/01.png" alt="banner_business" />
          </div>
          {/* banner single content end */}
        </div>
      </div>
      <div className="swiper-pagination" />
    </div>
    <div className="animation-img">
      <img className="shape-img two" src="assets/images/banner/shape/02.png" alt="banner_business" />
      <img className="shape-img three" src="assets/images/banner/shape/03.png" alt="banner_business" />
    </div>
  </div>
  {/* banner blank space area end */}
  {/* rts about us section start */}
  <div className="rts-about-area rts-section-gap bg-about-sm-shape">
    <div className="container">
      <div className="row g-5 align-items-center">
        {/* about left */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 mt_md--50 mt_sm--50">
          <div className="rts-title-area">
            <p className="pre-title">
              More About Us
            </p>
            <h2 className="title">We Provide Best IT
              Solution in Ludhiana</h2>
          </div>
          <div className="about-inner">
            <p className="disc">
              At DN Web Works, we are dedicated to providing exceptional IT solutions to meet the unique needs of our clients. With a team of highly skilled professionals and a customer-centric approach, we strive to deliver cutting-edge technology solutions that drive business growth and efficiency
            </p>
            {/* start about success area */}
            <div className="row about-success-wrapper">
              {/* left wrapper start */}
              <div className="col-lg-6 col-md-6">
                <div className="single">
                  <i className="fas fa-check" />
                  <p className="details">Expertise</p>
                </div>
                <div className="single">
                  <i className="fas fa-check" />
                  <p className="details">Tailored Solutions</p>
                </div>
                <div className="single">
                  <i className="fas fa-check" />
                  <p className="details">Comprehensive Services</p>
                </div>
              </div>
              {/* left wrapper end */}
              <div className="col-lg-6 col-md-6">
                <div className="single">
                  <i className="fas fa-check" />
                  <p className="details">Proactive Support
                  </p>
                </div>
                <div className="single">
                  <i className="fas fa-check" />
                  <p className="details">Reliability and Security</p>
                </div>
                <div className="single">
                  <i className="fas fa-check" />
                  <p className="details">Client Satisfaction</p>
                </div>
              </div>
            </div>
            {/* start about success area */}
            {/* about founder & get in touch start */}
            <div className="row about-founder-wrapper align-items-center mt--40">
              {/* left area start */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="author-inner">
                  {/* <a class="thumbnail" href="#"><img src="assets/images/about/sm-01.png" alt="dnweb_founder"></a>
                          <div class="founder-details">
                              <a href="team-details.html">
                                  <h6 class="title">R.K. Nirmal</h6>
                              </a>
                              <span>CEO & Founder</span>
                          </div> */}
                  <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/about-us`}>Read More</Link>
                </div>
              </div>
              {/* left area end */}
              {/* right founder area */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_sm--20">
                <div className="author-call-option">
                  <img className="authore-call" src="assets/images/about/call.svg" alt="call_founder" />
                  <div className="call-details">
                    <span>Call us anytime</span>
                    <a href="tel:+917973490508">
                      <h6 className="title">+91.797.349.0508</h6>
                    </a>
                  </div>
                </div>
              </div>
              {/* left founder area */}
            </div>
            {/* about founder & get in touch end */}
          </div>
        </div>
        {/* about right */}
        {/* about-right Start*/}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
          <div className="about-one-thumbnail">
            <img src=" assets/images/about/main/about-01.png" alt="about-dnweb" />
            <img className="small-img" src="assets/images/about/main/about-02.png" alt="dnweb-small" />
            <div className="experience">
              <div className="left single">
                <h2 className="title">2+</h2>
                <p className="time">Years</p>
              </div>
              <div className="right single">
                <p className="disc">
                  Of experience
                  in consulting
                  service
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* about-right end */}
      </div>
    </div>
  </div>
  {/* rts about us section end */}
  {/* rts service post area  Start*/}
  <div className="rts-service-area rts-section-gapBottom">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="rts-title-area service text-center">
            <p className="pre-title">
              Our Services
            </p>
            <h2 className="title">High Quality Services</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid service-main plr--120-service mt--50 plr_md--0 pl_sm--0 pr_sm--0">
      <div className="background-service row">
        {/* start single Service */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="service-one-inner one">
            <div className="thumbnail">
              <img src="assets/images/service/icon/01.svg" alt="dnweb_service" />
            </div>
            <div className="service-details">
              <Link  to={`${process.env.PUBLIC_URL}/web-designing`}>
              <h5 className="title">Web Designing</h5>
              </Link>
              <p className="disc">
                We offer professional web designing services as part of our comprehensive IT solutions. 
              </p>
              <Link className="rts-read-more btn-primary" to={`${process.env.PUBLIC_URL}/web-designing`}><i className="fas fa-arrow-right" />Read More</Link>
            </div>
          </div>
        </div>
        {/* end single Services */}
        {/* start single Service */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="service-one-inner two">
            <div className="thumbnail">
              <img src="assets/images/service/icon/02.svg" alt="dnweb_service" />
            </div>
            <div className="service-details">
              <Link  to={`${process.env.PUBLIC_URL}/web-development`}>
              <h5 className="title">Web Development</h5>
              </Link>
              <p className="disc">
                As an DN Web Works, we provide comprehensive web development services to help businesses..
              </p>
                <Link className="rts-read-more btn-primary" to={`${process.env.PUBLIC_URL}/web-development`}><i className="fas fa-arrow-right" />Read More</Link>
            </div>
          </div>
        </div>
        {/* end single Services */}
        {/* start single Service */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="service-one-inner three">
            <div className="thumbnail">
              <img src="assets/images/service/icon/03.svg" alt="dnweb_service" />
            </div>
            <div className="service-details">
             <Link  to={`${process.env.PUBLIC_URL}/graphic-designing`}>
              <h5 className="title">Graphic Designing</h5>
              </Link>
              <p className="disc"> 
                As an DN Web Works, we provide comprehensive graphic design services to help businesses..
              </p>
                <Link className="rts-read-more btn-primary" to={`${process.env.PUBLIC_URL}/graphic-designing`}><i className="fas fa-arrow-right" />Read More</Link>
            </div>
          </div>
        </div>
        {/* end single Services */}
        {/* start single Service */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="service-one-inner four">
            <div className="thumbnail">
              <img src="assets/images/service/icon/04.svg" alt="dnweb_service" />
            </div>
            <div className="service-details">
             
              <Link  to={`${process.env.PUBLIC_URL}/e-commerce-solutions`}>
              <h5 className="title">E-Commerce Solution</h5>
              </Link>
              <p className="disc">
                As an DN Web Works, we specialize in providing comprehensive e-commerce..
              </p>
                <Link className="rts-read-more btn-primary" to={`${process.env.PUBLIC_URL}/e-commerce-solutions`}><i className="fas fa-arrow-right" />Read More</Link>
            </div>
          </div>
        </div>
        {/* end single Services */}
        {/* start single Service */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="service-one-inner five">
            <div className="thumbnail">
              <img src="assets/images/service/icon/05.svg" alt="dnweb_service" />
            </div>
            <div className="service-details">
            <Link  to={`${process.env.PUBLIC_URL}/seo`}>
              <h5 className="title">SEO</h5>
              </Link>
              <p className="disc">
                In DN Web Works, we provide comprehensive SEO (Search Engine Optimization) solutions..
              </p>
                <Link className="rts-read-more btn-primary" to={`${process.env.PUBLIC_URL}/seo`}><i className="fas fa-arrow-right" />Read More</Link>
            </div>
          </div>
        </div>
        {/* end single Services */}
        {/* start single Service */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="service-one-inner six">
            <div className="thumbnail">
              <img src="assets/images/service/icon/06.svg" alt="dnweb_service" />
            </div>
            <div className="service-details">
              <a href="digital-marketing.html">
              <Link  to={`${process.env.PUBLIC_URL}/digital-marketing`}>
              <h5 className="title">Digital Marketing</h5>
              </Link>
                
              </a>
              <p className="disc">
                Digital marketing is a broad term that refers to the promotion of products, services,..
              </p>
                <Link className="rts-read-more btn-primary" to={`${process.env.PUBLIC_URL}/digital-marketing`}><i className="fas fa-arrow-right" />Read More</Link>
            </div>
          </div>
        </div>
        {/* end single Services */}
      </div>
      <div className="row">
        <div className="cta-one-bg col-12">
          <div className="cta-one-inner">
            <div className="cta-left">
              <h3 className="title">Let’s discuss about how we can help
                make your business better</h3>
            </div>
            <div className="cta-right">
              <Link className="rts-btn btn-white" to={`${process.env.PUBLIC_URL}/contact-us`}>
              Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts service post area ENd */}
  {/* business goal area */}
  <div className="rts-business-goal mt--0 rts-section-gapBottom">
    <div className="container">
      <div className="row">
        {/* business goal left */}
        <div className="col-lg-6">
          <div className="business-goal-one">
            <img src="assets/images/webdesign/web-design-dnww-1.jpg" alt="Business_Goal" />
            <img className="small" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="Business_Goal" />
          </div>
        </div>
        {/* business goal right */}
        {/* right area business */}
        <div className="col-lg-6 mt--35 mt_md--70 mt_sm--70">
          <div className="business-goal-right">
            <div className="rts-title-area business text-start pl--30">
              <p className="pre-title">
                WEB DESIGNING
              </p>
              <h2 className="title">Professional Website Designing Company In Ludhiana</h2>
            </div>
            <div className="rts-business-goal pl--30">
              <div className="single-goal">
                <img src="assets/images/business-goal/icon/01.svg" alt="business_Icone" className="thumb" />
                <div className="goal-wrapper">
                  <h6 className="title">Custom Website Design</h6>
                  <p className="disc">Our team of skilled web designers will work closely with you to create a custom website that reflects your brand identity, captures your unique value proposition, and provides a user-friendly experience.</p>
                </div>
              </div>
              <div className="single-goal">
                <img src="assets/images/business-goal/icon/02.svg" alt="business_Icone" className="thumb" />
                <div className="goal-wrapper">
                  <h6 className="title">Responsive Design</h6>
                  <p className="disc">Our web design team ensures that your website is mobile-friendly, providing an optimal user experience on smartphones, tablets, and desktops.</p>
                </div>
              </div>
              <div className="goal-button-wrapper mt--50">
                <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/contact-us`}> Contact Us </Link>
                <div className="ml--20">
                  <Link className="rts-btn btn-primary color-h-black" to={`${process.env.PUBLIC_URL}/web-designing`}>Read More</Link>
                </div>
                {/* <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                              <span class="outer-text">Watch Video</span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* right area business ENd */}
      </div>
    </div>
  </div>
  {/* business goal area End */}
  {/* rts-counter up area start */}
  {/* <div class="rts-counter-up-area rts-section-gap counter-bg">
    <div class="container">
  <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="single-counter">
              <img src="assets/images/counterup/icon/01.svg" alt="Business_counter">
              <div class="counter-details">
                  <h2 class="title"><span class="counter animated fadeInDownBig">858</span></h2>
                  <p class="disc">Successful Projects</p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="single-counter pl--10 justify-content-center two pl--30">
              <img src="assets/images/counterup/icon/02.svg" alt="Business_counter">
              <div class="counter-details">
                  <h2 class="title"><span class="counter animated fadeInDownBig">650</span></h2>
                  <p class="disc">Media Activities</p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="single-counter pl--10 justify-content-center three pl--50">
              <img src="assets/images/counterup/icon/03.svg" alt="Business_counter">
              <div class="counter-details">
                  <h2 class="title"><span class="counter animated fadeInDownBig">567</span></h2>
                  <p class="disc">Skilled Experts</p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="single-counter pl--10 justify-content-end four">
              <img src="assets/images/counterup/icon/04.svg" alt="Business_counter">
              <div class="counter-details">
                  <h2 class="title happy"><span class="counter animated fadeInDownBig">28</span></h2>
                  <p class="disc">Happy Clients</p>
              </div>
          </div>
      </div>
  </div>
    </div>
</div> */}
  {/* rts-counter up area end */}
  {/* start gallery section */}
  {/* <div class="rts-gallery-area rts-section-gap gallery-bg bg_image">
    <div class="container">
  <div class="row">
      <div class="rts-title-area gallery text-start pl_sm--20">
          <p class="pre-title">
              Popular Projects
          </p>
          <h2 class="title">Our Completed Projects</h2>
      </div>
  </div>
  <div class="row mt--45">
      <div class="col-12">
          <div class="swiper mygallery mySwipers">
              <div class="swiper-wrapper gallery">
                  <div class="swiper-slide">
                      <div class="row g-5 w-g-100">
                          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                              <div class="thumbnail-gallery">
                                  <img src="assets/images/gallery/gallery-01.jpg" alt="business-images">
                              </div>
                          </div>
                          <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                              <div class="bg-right-gallery">
                                  <div class="icon">
                                      <img src="assets/images/gallery/icon/01.svg" alt="Business-gallery">
                                  </div>
                                  <a href="#">
                                      <h4 class="title">Pro Business Solution</h4>
                                  </a>
                                  <span>Case Study, Growth</span>
                                  <p class="disc">Ornare etiam laoreet dictumst nisl quisque scelerisque cras
                                      ut porta interdum purus mattis iaculis litora turpis torquent posuere.
                                  </p>
                                  <a class="rts-btn btn-primary" href="project-details.html">View Project</a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="row g-5 w-g-100">
                          <div class="col-lg-7">
                              <div class="thumbnail-gallery">
                                  <img src="assets/images/gallery/gallery-02.jpg" alt="business-images">
                              </div>
                          </div>
                          <div class="col-lg-5">
                              <div class="bg-right-gallery">
                                  <div class="icon">
                                      <img src="assets/images/gallery/icon/01.svg" alt="Business-gallery">
                                  </div>
                                  <a href="#">
                                      <h4 class="title">dnweb Pro Business</h4>
                                  </a>
                                  <span>Case Study, Growth</span>
                                  <p class="disc">In the literal sense, the term “Business” means the state of
                                      being busy. But it is a very wide connotation of business.</p>
                                  <a class="rts-btn btn-primary" href="project-details.html">View Project</a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="row g-5 w-g-100">
                          <div class="col-lg-7">
                              <div class="thumbnail-gallery">
                                  <img src="assets/images/gallery/gallery-03.jpg" alt="business-images">
                              </div>
                          </div>
                          <div class="col-lg-5">
                              <div class="bg-right-gallery">
                                  <div class="icon">
                                      <img src="assets/images/gallery/icon/01.svg" alt="Business-gallery">
                                  </div>
                                  <a href="#">
                                      <h4 class="title">Pro Solution Business</h4>
                                  </a>
                                  <span>Case Study, Growth</span>
                                  <p class="disc">But a very wide of business because it
                                      covers every human activity. Business is really concerned with the
                                      production.
                                  </p>
                                  <a class="rts-btn btn-primary" href="project-details.html">View Project</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-pagination"></div>
          </div>
      </div>
  </div>
    </div>
</div> */}
  {/* start gallery section */}
  {/* start trusted client section */}
  <div className="rts-trusted-client rts-section-gapBottom ">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="title-area-client text-center">
            <p className="client-title">Technologies</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="client-wrapper-one">
          <a href="#"><img src="assets/images/technology/1.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/2.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/3.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/4.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/5.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/6.jpg" alt="business_dnweb" /></a>
        </div>
      </div>
    </div>
  </div>
  {/* end trusted client section */}
  {/* rts features area start */}
  {/* <div class="rts-feature-area rts-feature-area-home rts-section-gap">
    <div class="container-fluid padding-controler plr--120">
  <div class="row bg-white-feature  pt--120 pb--120">
      <div class="col-xl-6 col-lg-12">
          <div class="feature-left-area">
              <img src="assets/images/feature/01.png" alt="">
          </div>
      </div>
      <div class="col-xl-6 col-lg-12">
          <div class="rts-title-area feature text-start pl--30 pl_sm--0">
              <p class="pre-title">
                  Boost Your Business Today
              </p>
              <h2 class="title feature-title">Most Reliable Efficient <br>
                  Consulting Agency</h2>
              <p>Porttitor ornare fermentum aliquam pharetra ut facilisis gravida risus suscipit feugiat fusce
                  conubia ridiculus tristique solving parturient natoque vulputate risu hasellus quam
                  tincidunt posuere aliquam accumsan</p>

          </div>

          <div class="feature-one-wrapper pl--30 mt--40 pl_sm--0">
              <div class="single-feature-one">
                  <i class="fas fa-check"></i>
                  <p>Fast Growing Sells</p>
              </div>
              <div class="single-feature-one">
                  <i class="fas fa-check"></i>
                  <p>24/7 Quality Services</p>
              </div>
              <div class="single-feature-one">
                  <i class="fas fa-check"></i>
                  <p>Expert Members</p>
              </div>
              <div class="single-feature-one">
                  <i class="fas fa-check"></i>
                  <p>Best Quality Services</p>
              </div>
          </div>
      </div>
  </div>
    </div>
</div> */}
  {/* rts features area end */}
  {/* start client feed back section */}
  <div className="rts-client-feedback">
    <div className="container">
      <div className="row">
        {/* start testimonials area */}
        <div className="col-lg-7">
          <div className="rts-title-area reviews text-start pl--30 pt--70">
            <p className="pre-title">
              Our Testimonials
            </p>
            <h2 className="title">Client’s Feedbacks</h2>
            {/* swiper area start */}
            <div className="swiper mySwipertestimonial">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-inner">
                    <p className="disc text-start">
                      “DN web works worked on our website and made it go live in a span of just a few hours.Amazing service from the very beginning till the completion of the project.”
                    </p>
                    <div className="testimonial-bottom-one">
                      <div className="thumbnail">
                        <img src="assets/images/testimonials/02.png" alt="business-testimonials" />
                      </div>
                      <div className="details">
                        <a href="#">
                          <h5 className="title">Umesh Kumar</h5>
                        </a>
                        {/* <span>JHKL Owner</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-inner">
                    <p className="disc text-start">
                      “DN web works worked on our website and made it go live in a span of just a few hours.Amazing service from the very beginning till the completion of the project.”
                    </p>
                    <div className="testimonial-bottom-one">
                      <div className="thumbnail">
                        <img src="assets/images/testimonials/02.png" alt="business-testimonials" />
                      </div>
                      <div className="details">
                        <a href="#">
                          <h5 className="title">Umesh Kumar</h5>
                        </a>
                        {/* <span>JHKL Owner</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-inner">
                    <p className="disc text-start">
                      “DN web works worked on our website and made it go live in a span of just a few hours.Amazing service from the very beginning till the completion of the project.”
                    </p>
                    <div className="testimonial-bottom-one">
                      <div className="thumbnail">
                        <img src="assets/images/testimonials/02.png" alt="business-testimonials" />
                      </div>
                      <div className="details">
                        <a href="#">
                          <h5 className="title">Umesh Kumar</h5>
                        </a>
                        {/* <span>JHKL Owner</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-button-next" />
              <div className="swiper-button-prev" />
            </div>
            {/* swiper area end */}
          </div>
        </div>
        {/* end testimonials are */}
        {/* images area */}
        <div className="col-lg-5">
          <div className="rts-test-one-image-inner">
            <img src="assets/images/testimonials/01.png" alt="business_testimobials" />
          </div>
        </div>
        {/* image area end */}
      </div>
    </div>
  </div>
  {/* start client feed back section End */}
  
  {/* ENd Header Area */}
  
</div>

    </>
  )
}
