import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function EcommerceSolutions() {
  return (
    <>
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-ec bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">E-Commerce Solution</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">E-Commerce Solution</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1 img-round" src="assets/images/webdesign/web-design-dnww-1.jpg" alt="BUsiness_image" />
              <img className="img-over img-round" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>E-Commerce Solution</span>
              <h2 className="title">E-Commerce Development</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">As an <strong>DN Web Works</strong>, we specialize in providing comprehensive e-commerce services to businesses looking to establish or enhance their online presence. Our e-commerce services cover a wide range of aspects to ensure a successful and seamless online shopping experience for your customers.</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg ">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">E-COMMERCE SOLUTIONS</span>
              <h2 className="title">E-Commerce Development   
                {/* <span class="sm-title">to Help <span>Businesses</span></span> */}
                <span>Services to Help <span style={{color: '#ff0000'}}>Businesses</span></span>
              </h2>
              <p style={{color: '#ffffff'}}>As an <strong>DN Web Works</strong>, we offer comprehensive e-commerce services to help businesses establish and grow their online presence. Our e-commerce services include:</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span>E-commerce Website Development:
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We have a team of skilled developers who can create a custom e-commerce website tailored to your specific business requirements. We leverage industry-leading platforms such as Magento, Shopify, WooCommerce, or develop custom solutions to build a secure, scalable, and user-friendly online store.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Responsive Design:
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      With the growing popularity of mobile shopping, we ensure that your e-commerce website is fully responsive and optimized for different devices. This allows your customers to browse and make purchases seamlessly from their smartphones, tablets, or desktops, enhancing their overall shopping experience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> Product Management:
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We provide robust product management solutions, enabling you to efficiently manage your product catalog. From adding new products and updating prices to managing stock levels and categorizing products, our systems make it easy for you to maintain accurate and up-to-date product information.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span>Shopping Cart and Payment Integration:
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We integrate secure and user-friendly shopping cart systems into your e-commerce website. This allows customers to add items to their cart, calculate totals, apply discounts, and proceed to a smooth and secure checkout process. We also integrate popular payment gateways to enable seamless transactions and provide a variety of payment options to your customers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> Order Processing and Management:
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We develop comprehensive order processing and management systems, allowing you to efficiently handle incoming orders. Our solutions include features such as order tracking, email notifications, inventory management, and integration with shipping providers, ensuring a streamlined order fulfillment process.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span> Customer Account Management: 
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We implement customer account features that enable customers to create accounts, manage their profiles, track order history, and save payment and shipping information for future purchases. This provides convenience for returning customers and enhances their overall shopping experience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span> Security and Data Protection: 
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We prioritize the security of your e-commerce website and customer data. We implement robust security measures, including SSL certificates, encryption, and secure payment gateways, to ensure secure transactions and protect sensitive customer information.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span> E-commerce Analytics and Reporting:
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We integrate analytics and reporting tools to provide insights into your e-commerce performance. You can track key metrics such as website traffic, conversion rates, customer behavior, and sales data, enabling you to make data-driven decisions and optimize your online store for better results.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingnouw">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenouw" aria-expanded="false" aria-controls="collapsenouw">
                      <span>09. </span>  Digital Marketing and SEO:
                    </button>
                  </h2>
                  <div id="collapsenouw" className="accordion-collapse collapse" aria-labelledby="headingnouw" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We offer digital marketing and search engine optimization (SEO) services to increase the visibility and reach of your e-commerce website. Our strategies include optimizing product pages for search engines, implementing SEO best practices, and utilizing various digital marketing channels to drive targeted traffic and increase conversions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingtaan">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetaan" aria-expanded="false" aria-controls="collapsetaan">
                      <span>10. </span>  Ongoing Support and Maintenance:
                    </button>
                  </h2>
                  <div id="collapsetaan" className="accordion-collapse collapse" aria-labelledby="headingtaan" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We provide reliable support and maintenance services to ensure your e-commerce website operates smoothly. We handle updates, security patches, bug fixes, and provide technical assistance to address any issues that may arise, allowing you to focus on your core business.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  <section className="dnw-section-gap">
    <div className="container">
      <p className="disc">
        By choosing our e-commerce services, you can leverage our expertise to build a robust and successful online store that drives sales and provides an exceptional shopping experience for your customers. Contact us today to discuss your e-commerce needs, and let us help you achieve your online business goals.
      </p>
    </div>
  </section>
  {/* start Pricing section */}
  <section id="pricingSection" className="dnw-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title text-center mt-20">E-Commerce Pricing in Ludhiana</h2>
          <br />
          <div className="table-responsive">
            <table>
              <tbody><tr>
                  <td className="cell-1 first">
                    {/* <img src="assets/images/pricing/2000-pro.jpg" width="203" height="119" alt="" class="img"> */}
                    <div className="name">Starter Package</div>
                  </td>
                  <td className="cell-2"><div className="name"> Basic Plan </div>
                    <div className="head"> Up to <span> 10 Pages </span> </div></td>
                  <td className="cell-3 highlighted"><div className="name">Standard Plan</div>
                    <div className="head"> Up to <span> 25 Pages </span> </div></td>
                  <td className="cell-4"><div className="name"> Enterprise Plan </div>
                    <div className="head"> <span>Unlimited Pages </span></div></td>
                </tr>
                <tr>
                  <td>Domain Name</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Social Links Intigration</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Free Webb Hosting (1 Year)</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Home &amp; Internal Web Pages</td>
                  <td><span className="gray1">10</span></td>
                  <td><span className="gray1">25</span></td>
                  <td><span className="gray1">Unlimited</span></td>
                </tr>
                <tr>
                  <td>Corporate Email ID's </td>
                  <td><span className="gray1">5 Email ID's</span></td>
                  <td><span className="gray1">15 Email ID's</span></td>
                  <td><span className="gray1">Unlimited Email ID's</span></td>
                </tr>
                <tr>
                  <td>Search Engine Optimization (SEO)</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="gray1">Initial 1 Month SEO</span></td>
                </tr>
                <tr>
                  <td>Contact Form</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Product/Photo Gallery Management</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Custom Design</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Google Location Map</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Mobile/iPad Compatablility</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Visitor Counter</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>CMS</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Shopping Cart</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Payment Gateways</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Technical Support</td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /> <br />
                    for 3 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 6 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                </tr>
                <tr>
                  <td className="btm-lft">Call Us at<br />
                    <span className="gray-txt"><strong><a href="tel:+917973490508"> +91-79734-90508 </a></strong></span><br />
                    <img src="assets/images/pricing/or.png" width={183} height={18} alt className="or-img" /><br />
                    Email Us at <br />
                    <a href="mailto:dnwebworks@gmail.com"><strong>dnwebworks@gmail.com</strong></a></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 29,999/-</div>
                    <div className="enquire"> <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 49,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 95,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end of pricing section */}
</div>

    
    </>
  )
}
