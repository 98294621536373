import React, { Component } from 'react';
import {  Link } from "react-router-dom";
import GoToTop from './GotoTop';


function refreshPage() {
  setTimeout(()=>{
      window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}
export default class QuikLinks extends Component {
    render() {
        return (
            <>
             <div className="col-xl-5 col-md-6 col-sm-12 col-12">
          <div className="footer-one-single-wized">
            <div className="wized-title">
              <h5 className="title">Quick Links</h5>
              <img src="assets/images/footer/under-title.png" alt="dnweb_footer" />
            </div>
            <div className="quick-link-inner">
              <ul className="links">
                <li>
                <Link to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}><i className="fas fa-arrow-right" /> Home</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/about-us`} ><i className="fas fa-arrow-right" /> About Us</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/it-solutions`} ><i className="fas fa-arrow-right" /> IT Solutions</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/web-designing`} ><i className="fas fa-arrow-right" /> Web Designing</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/web-development`} ><i className="fas fa-arrow-right" /> Web Development</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/graphic-designing`} ><i className="fas fa-arrow-right" /> Graphic Designing</Link>
                </li>
              </ul>
              <ul className="links margin-left-70">
              <li>
                <Link to={`${process.env.PUBLIC_URL}/e-commerce-solutions`} ><i className="fas fa-arrow-right" /> E-Commerce Solutions</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/seo`} ><i className="fas fa-arrow-right" /> SEO</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/digital-marketing`} ><i className="fas fa-arrow-right" /> Digital Marketing</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/pricing`} ><i className="fas fa-arrow-right" /> Pricing</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/contact-us`} ><i className="fas fa-arrow-right" /> Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <GoToTop/>
            </>
        )
    }
}
