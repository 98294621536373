import React from 'react'
import { Link } from 'react-router-dom'

export default function TimeTable() {
  return (
    <>
        {/* footer mid area */}
        <div className="col-xl-4 col-md-6 col-sm-12 col-12">
          <div className="footer-one-single-wized mid-bg">
            <div className="wized-title">
              <h5 className="title">Working Hours</h5>
              <img src="assets/images/footer/under-title.png" alt="dnweb_footer" />
            </div>
            <div className="opening-time-inner">
              <div className="single-opening">
                <p className="day">Mon to Sat :-</p>
                <p className="time">10:00 AM - 9:00 PM</p>
              </div>
              {/* <div class="single-opening">
                      <p class="day">Saturday</p>
                      <p class="time">08:00 - 03.00</p>
                  </div> */}
              <div className="single-opening mb--30 mb_sm--10">
                <p className="day">Sunday :-</p>
                <p className="time">Day Off</p>
              </div>
              <Link className="rts-btn btn-primary contact-us" to={`${process.env.PUBLIC_URL}/contact-us`} > Contact Us</Link>
            </div>
          </div>
        </div>
        {/* footer mid area end */}
    </>
  )
}
