// import React, { Component } from 'react'

// export default class CopyRightReserve extends Component {
//     render() {
//         return (
//             <>
//     <div className="rts-copyright-area">
//       <div className="container">
//         <div className="row">
//           <div className="col-12">
//             <div className="text-center">
//               <p>DN Web Works - © Copyrights, 
//                {/* <span className="dynamic-year" /> */} 2023
//               . All rights reserved.</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//             </>
//         )
//     }
// }

import React from 'react';

const CopyRightReserve = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="rts-copyright-area">
       <div className="container">
         <div className="row">
           <div className="col-12">
             <div className="text-center">
               <p>DN Web Works - &copy; Copyrights ,  <span className="dynamic-year">{currentYear}</span> . All rights reserved.</p>
             </div>
           </div>
         </div>
       </div>
    </div>
    </>
  );
};

export default CopyRightReserve;
