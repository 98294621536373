import React from 'react'
import { Link } from 'react-router-dom'

export default function ActionArea() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="rts-cta-wrapper">
            <div className="background-cta">
              <div className="row align-items-center">
                {/* cta-left */}
                <div className="col-lg-9">
                  <div className="cta-left-wrapepr">
                    <p className="cta-disc">
                      Would you like to work with DN Web Works – Website Designer in Ludhiana?
                    </p>
                    <h3 className="title">Would you like to start a project with us?</h3>
                  </div>
                </div>
                {/* cta left end */}
                <div className="col-lg-3 dn-ftr-cont">
                  {/* cta right */}
                  {/* <form class="cta-input-arae">
                              <input type="email" name="email" placeholder="Enter Email Address" required>
                              <button type="submit" class="rts-btn btn-primary">Subscribe Now</button>
                          </form> */}
                  <Link className="rts-btn btn-white" title="contact us" to={`${process.env.PUBLIC_URL}/contact-us`} >Let's Talk</Link>
                  {/* cta right End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
