import React, { Component } from 'react'
import {   
    Routes ,
    Route,
  } from "react-router-dom";

// import Home from '../InnerComponents/Home/Index'
// import AboutUs from '../InnerComponents/AboutUs/Index'
// import AirCargoSalesServices from '../InnerComponents/Services/Specialized-Air-Cargo/Index';
// import AirlineCargoSalesAndFacilitation from '../InnerComponents/Services/Airline-Cargo-Sales-And-Facilitation/Index';
// import WarehousingDryGoodsAndSpecializedCargo from '../InnerComponents/Services/Warehousing-Dry-Goods-and-Specialized-Cargo/Index';
// import Perishables from '../InnerComponents/Services/Perishables/Index';
// import SpecializedCargoHandlingAndCharters from '../InnerComponents/Services/SpecializedCargoHandlingAndCharters/Index';
// import TruckingAndLogistics from '../InnerComponents/Services/TruckingAndLogistics/Index';
// import EndToEndTruckProvisioningAndMaintenance from '../InnerComponents/Services/End-To-End-Truck-Provisioning-And-Maintenance/Index';
// import FleetParkingAndYardManagement from '../InnerComponents/Services/FleetParkingAndYardManagement/Index';
// import ContactUs from '../InnerComponents/ContactUs/Index';
// import Blog from '../InnerComponents/Blog/Index';
// // Three Box Links
// import AirlineCargoSalesAndFacilitation2 from '../InnerComponents/BoxServices/Airline-Cargo-Sales-And-Facilitation/Index'
// // import WarehousingDryGoodsAndSpecializedCargo2 from '../InnerComponents/BoxServices/Warehousing-Dry-Goods-and-Specialized-Cargo/Index'
// // import EndToEndTruckProvisioningAndMaintenance2 from '../InnerComponents/BoxServices/End-To-End-Truck-Provisioning-And-Maintenance/Index'
// // End

import Home from '../pages/home/index'
import AboutUs from '../pages/Aboutus'
import ITSolutions from '../pages/ITSolutions'
import WebDesigning from '../pages/WebDesigning'
import WebDevelopment from '../pages/WebDevelopment'
import DigitalMarketing from '../pages/DigitalMarketing'
import ContactUs from '../pages/Contactus'
import EcommerceSolutions from '../pages/EcommerceSolutions'
import GraphicDesigning from '../pages/GraphicDesigning'
import Pricing from '../pages/Pricing'
import Seo from '../pages/Seo'
import WebDesigningPackages from '../pages/WebDesigningPackages'
function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 0);
    // console.log('page to reload')
  }

export default class Index extends Component {
    render() {
        return (
            <>
            <Routes>
            {/* <Route path={`${process.env.PUBLIC_URL}/`} exact element={<Home />}  onClick={refreshPage}/>
            <Route path={`${process.env.PUBLIC_URL}/about-us`} element={<AboutUs />} />
            <Route path={`${process.env.PUBLIC_URL}/specialized-air-cargo`} element={<AirCargoSalesServices />} />
            <Route path={`${process.env.PUBLIC_URL}/airline-cargo-sales-and-facilitation`} element={<AirlineCargoSalesAndFacilitation />} />
            <Route path={`${process.env.PUBLIC_URL}/warehousing`} element={<WarehousingDryGoodsAndSpecializedCargo />} />
            <Route path={`${process.env.PUBLIC_URL}/Perishables`} element={<Perishables />} />
            <Route path={`${process.env.PUBLIC_URL}/cargo-handling-and-charters`}  element={<SpecializedCargoHandlingAndCharters />} />
            <Route path={`${process.env.PUBLIC_URL}/trucking-and-logistics`} element={<TruckingAndLogistics />} />
            <Route path={`${process.env.PUBLIC_URL}/truck-provisioning-and-maintenance`} element={<EndToEndTruckProvisioningAndMaintenance />} />
            <Route path={`${process.env.PUBLIC_URL}/fleet-parking-and-yard-management`}  element={<FleetParkingAndYardManagement />} />
            <Route path={`${process.env.PUBLIC_URL}/contact-us`}  element={<ContactUs />} />
            <Route path={`${process.env.PUBLIC_URL}/blog`} element={<Blog />} />}
            <Route path={`${process.env.PUBLIC_URL}/airline-cargo-sales-services`} element={<AirlineCargoSalesAndFacilitation2 />} /> */}
            <Route path={`${process.env.PUBLIC_URL}/`} exact element={<Home />}  onClick={refreshPage}/>
            <Route path={`${process.env.PUBLIC_URL}/about-us`} element={<AboutUs /> } />
            <Route path={`${process.env.PUBLIC_URL}/it-solutions`} element={<ITSolutions />} />
            <Route path={`${process.env.PUBLIC_URL}/web-designing`} element={<WebDesigning />} />
            <Route path={`${process.env.PUBLIC_URL}/graphic-designing`} element={<GraphicDesigning />} />
            <Route path={`${process.env.PUBLIC_URL}/web-development`} element={<WebDevelopment />} />
            <Route path={`${process.env.PUBLIC_URL}/e-commerce-solutions`} element={<EcommerceSolutions />} />
            <Route path={`${process.env.PUBLIC_URL}/seo`}  element={<Seo />} />
            <Route path={`${process.env.PUBLIC_URL}/digital-marketing`} element={<DigitalMarketing />} />
            <Route path={`${process.env.PUBLIC_URL}/pricing`} element={<Pricing />} />
            <Route path={`${process.env.PUBLIC_URL}/contact-us`}  element={<ContactUs />} />
            <Route path={`${process.env.PUBLIC_URL}/web-designing-packages`}  element={<WebDesigningPackages />} />
           
        </Routes>
        </>
        )
    }
}
