import React from 'react'
import {  Link } from "react-router-dom";



export default function Header() {
  return (
    <>
        {/* start header area */}
  <header className="header--sticky header-one ">
    <div className="header-top header-top-one bg-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-xl-block d-none">
            <div className="left">
              <div className="mail">
                <a href="mailto:info@dnwebworks.com"><i className="fas fa-envelope" /> info@dnwebworks.com</a>
              </div>
              <div className="phonedn">
                <a href="tel:+917973490508"><i className="fas fa-phone" /> +91 797.349.0508</a>
              </div>
              {/* <div class="working-time ">
                      <p><i class="fas fa-clock"></i> Working: 8.00am - 5.00pm</p>
                  </div> */}
            </div>
          </div>
          <div className="col-lg-6 d-xl-block d-none">
            <div className="right">
              <ul className="top-nav lists-dn">
                {/* <li><a href="about-us.html">About</a></li>
                      <li><a href="blog-list.html">News</a></li>
                      <li><a href="contactus.html">Contact</a></li> */}
                <li>
                <Link className="blink" title="Web Desinging Packages Ludhiana" to={`${process.env.PUBLIC_URL}/web-designing-packages`}><strong>Web Designing Packages</strong></Link>
               </li>
              </ul>
              <ul className="social-wrapper-one">
                <li><a href="https://www.facebook.com/dnwebworks" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="https://twitter.com/dnwebworks" target="_blank"><i className="fab fa-twitter" /></a></li>
                <li><a href="https://www.instagram.com/dnwebworks/" target="_blank"><i className="fab fa-instagram" /></a></li>
                <li> <a href="https://api.whatsapp.com/send?phone=918288012437&text=Hello this is the starting message DN Web Works." target="_blank"><i className="fab fa-whatsapp" /></a></li>
                {/* <li><a class="mr--0" href="#"><i class="fab fa-linkedin-in"></i></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="header-main-one bg-white">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <div className="thumbnail logo-header-dnweb">
              {/* <a href="index.html">
                <img src="assets/images/logo/logo-1.png" alt="dnweb-logo" />
              </a> */}
              <Link to={`${process.env.PUBLIC_URL}/`} >
              <img src="assets/images/logo/logo-1.png" alt="dnweb-logo" /></Link>
            </div>
          </div>
          <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
            <div className="main-header">
              <nav className="nav-main mainmenu-nav d-none d-xl-block">
                <ul className="mainmenu">
                  <li>
                  <Link className="nav-item" to={`${process.env.PUBLIC_URL}/`}   >Home</Link>
                  </li>
                  <li>
                  <Link className="nav-item" to={`${process.env.PUBLIC_URL}/about-us`} >About Us</Link>
                  </li>
                  <li>
                  <Link className="nav-item" to={`${process.env.PUBLIC_URL}/it-solutions`}>IT Solutions</Link>
                  </li>
                  <li className="has-droupdown">
                    <a className="nav-link" href="#">Our Services</a>
                    <ul className="submenu menu-link3">
                      <li>
                      <Link  to={`${process.env.PUBLIC_URL}/web-designing`}>Web Designing</Link>
                      </li>
                      <li>
                      <Link  to={`${process.env.PUBLIC_URL}/web-development`}>Web Development</Link>
                      </li>
                      <li>
                      <Link  to={`${process.env.PUBLIC_URL}/graphic-designing`}>Graphic Designing</Link>
                      </li>
                      <li>
                      <Link  to={`${process.env.PUBLIC_URL}/e-commerce-solutions`}>E-commerce Soloutions</Link>
                      </li>
                      <li>
                      <Link  to={`${process.env.PUBLIC_URL}/seo`}>SEO</Link>
                      </li>
                      <li>
                      <Link  to={`${process.env.PUBLIC_URL}/digital-marketing`}>Digital Marketing</Link>
                      </li>
                      {/* <li class="sub-droupdown">
                                      <a class="sub-menu-link" href="#">Our Service</a>
                                      <ul class="submenu third-lvl">
                                          <li><a href="our-service.html">Service 1</a></li>
                                          <li><a href="service-2.html">Service 2</a></li>
                                          <li><a href="service-3.html">Service 3</a></li>
                                      </ul>
                                  </li> */}
                      {/* <li><a href="service-details.html">Service Details</a></li> */}
                    </ul>
                  </li>
                  <li>
                  <Link className="nav-item" to={`${process.env.PUBLIC_URL}/pricing`}>Pricing</Link>
                  </li>
                  <li>
                  <Link className="nav-item" to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link>
                  </li>
                </ul>
              </nav>
              <div className="button-area">
                {/* <button id="search" class="rts-btn btn-primary-alta"><i class="fas fa-search"></i></button> */}
                <a href="#" className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btn">Get Quote</a>
                <button id="menu-btn" className="menu rts-btn btn-primary-alta ml--20 ml_sm--5">
                  <img className="menu-dark" src="assets/images/icon/menu.png" alt="Menu-icon" />
                  <img className="menu-light" src="assets/images/icon/menu-light.png" alt="Menu-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  {/* End header area */}
    </>
  )
}
