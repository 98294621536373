import React from 'react';
import {  Link } from "react-router-dom";
import FormOne from '../Common-components/ContactForm/FormOne';
// import ContactForm from '../ContactUs/ContactForm';
// import Form from '../From/Index';
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function Contactus() {
  return (
    <>
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-cont bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">Contact Us</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  <div className="rts-contact-area contact-one">
    <div className="container">
      <div className="row align-items-center g-0">
        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
          <div className="contact-image-one">
            <img src="assets/images/contact/01.jpg" alt />
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
          <div className="contact-form-area-one">
            <div className="rts-title-area contact text-start">
              <p className="pre-title">
                Tell us a bit about your project, we'll get in touch with you soon
              </p>
              <h2 className="title">GET IN TOUCH</h2>
            </div>
            <div id="form-messages" />
           <FormOne/>
           {/* <Form/> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Map Area */}
  <div className="rts-map-area bg-light-white">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-lg-6">
          {/* map area left */}
          <div className="mapdetails-inner-one">
            <div className="left-area single-wized">
              <h5 className="title">Get in touch</h5>
              <div className="details">
                <p>Work and general inquiries</p>
                <a className="number" href="tel:+917973490508">+91 797.349.0508</a>
                {/* <p class="time-header">
                          Assistance hours:
                      </p>
                      <p class="time">
                          Monday – Saturday <br> 24\7
                      </p> */}
                <p className="time-header">
                  Follow Us:
                </p>
                <p className="social-links-contact-p">
                </p><ul className="social-wrapper-contact">
                  <li><a href="https://www.facebook.com/dnwebworks" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                  <li><a href="https://twitter.com/dnwebworks" target="_blank"><i className="fab fa-twitter" /></a></li>
                  <li><a href="https://www.instagram.com/dnwebworks/" target="_blank"><i className="fab fa-instagram" /></a></li>
                  <li> <a href="https://api.whatsapp.com/send?phone=918288012437&text=Hello this is the starting message DN Web Works." target="_blank"><i className="fab fa-whatsapp" /></a></li>
                  {/* <li><a class="mr--0" href="#"><i class="fab fa-linkedin-in"></i></a></li> */}
                </ul>
                <p />
              </div>
            </div>
            <div className="right-area single-wized">
              <h5 className="title">Post Address</h5>
              <div className="details">
                <p>Service Office</p>
                <a href="https://goo.gl/maps/TKhGAniDaWuWjk8Q6">Near Amrit Dharam Kanda,Puneet Nagar, Tajpur Road Ludhiana,<br />
                  Punjab INDIA</a>
                <p className="headoffice">
                  Head Office
                </p>
                <p className="office">Near Amrit Dharam Kanda, Puneet Nagar,
                  Tajpur Road Ludhiana</p>
              </div>
            </div>
          </div>
          {/* map area right */}
        </div>
        <div className="col-lg-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13691.559799424982!2d75.8997273!3d30.9177001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a9d561d8af65f%3A0xc249a08594245076!2sDN%20Web%20Works!5e0!3m2!1sen!2sin!4v1679652411800!5m2!1sen!2sin" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
        </div>
      </div>
    </div>
  </div>
</div>

 
    </>
  )
}
