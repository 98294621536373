import React from 'react'
import Header from './Common-components/Header/Header';
import Sidebar from './Common-components/Sidebar/Sidebar';
import Footer from './Common-components/Footer/Index';
import Loader from './Common-components/Footer/Loader';
import BackTotop from './Common-components/Footer/BackTotop';
import RightButton from './Common-components/Footer/RightButton';
export default function Layout(props) {
    return (
       <>
        <Header/>
        <Sidebar/>
        {props.children}
        <Loader/>
        <BackTotop/>
        <RightButton/>
        <Footer/>
       </>
    )
}
