import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}
export default function Aboutus() {
  return (
    <>
        
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">About Us</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
            <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">About Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1" src="assets/images/about/main/about-03.jpg" alt="BUsiness_image" />
              <img className="img-over" src="assets/images/about/main/about-04.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>ABOUT COMPANY</span>
              <h2 className="title">Get to Know About DN Web Works</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">At DN Web Works, we are dedicated to providing exceptional IT solutions to meet the unique needs of our clients. With a team of highly skilled professionals and a customer-centric approach, we strive to deliver cutting-edge technology solutions that drive business growth and efficiency.</p>
              <p className="disc">
                Our company was founded on the belief that technology should be an enabler, not a barrier, to success. We understand the challenges businesses face in today's rapidly evolving digital landscape, and we are committed to empowering our clients with the right IT infrastructure, systems, and support to stay ahead of the competition.</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link className="rts-btn btn-primary" to={`${process.env.PUBLIC_URL}/contact-us`} > Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts about us section end */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-12">
          <div className="about-progress-inner-dn">
            {/* inner start */}
            <div className="inner">
              <div className="accordion-service-inner inner-dn">
                <div className="title-area-start">
                  <span className="sub color-primary">You can expect a reliable and innovative IT partner</span>
                  <h2 className="title">Here are some key aspects that define us:</h2>
                </div>
                <div className="accordion-area">
                  <div className="accordion" id="accordionExample3">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                          Expertise
                        </button>
                      </h2>
                      <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample3" style={{}}>
                        <div className="accordion-body">
                          Our team consists of experienced IT professionals who possess a diverse range of skills and expertise. From network design and implementation to cybersecurity and cloud solutions, we have the knowledge and capabilities to handle various IT requirements.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          Tailored Solutions
                        </button>
                      </h2>
                      <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample3" style={{}}>
                        <div className="accordion-body">
                          We recognize that every business is unique, with its own specific goals and challenges. That's why we take a customized approach to our services, working closely with our clients to understand their individual needs and designing solutions that align with their objectives.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                          Comprehensive Services
                        </button>
                      </h2>
                      <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          We offer a wide range of IT services to cater to different aspects of your business's technology needs. This includes IT consulting, infrastructure setup and management, software development, data backup and recovery, cybersecurity, cloud solutions, and ongoing IT support.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                          Proactive Support
                        </button>
                      </h2>
                      <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          We believe in being proactive rather than reactive when it comes to IT support. Our team closely monitors your systems, identifies potential issues, and takes preventive measures to ensure smooth operations. We also provide timely assistance and troubleshooting whenever problems arise.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                          Reliability and Security
                        </button>
                      </h2>
                      <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          We prioritize the security and reliability of your IT infrastructure. We implement robust security measures to protect your data and systems from cyber threats, while also ensuring that your technology functions optimally and consistently.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingten">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                          Client Satisfaction
                        </button>
                      </h2>
                      <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          Client satisfaction is at the core of our business. We strive to build long-term partnerships with our clients based on trust, transparency, and exceptional service. We are committed to delivering value and exceeding expectations, consistently aiming for the highest levels of client satisfaction.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="disc mt-5 mb-3">
                  By choosing DN Web Works, you can expect a reliable and innovative IT partner who will work alongside you to achieve your business objectives. We are passionate about technology and dedicated to helping your business thrive in the digital age.</p>
                <p className="disc">
                  Contact us today to discuss your IT needs and let us show you how our services can empower your business for success.
                </p>
              </div>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">WHY CHOOSE US</span>
              <h2 className="title">We Are Experienced
                <span className="sm-title">Business <span>Solution</span></span>
              </h2>
              <p style={{color: '#ffffff'}}>When it comes to choosing an IT services provider, we understand that you have many options available. However, there are several compelling reasons why you should choose us at DN Web Works</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span> Expertise and Experience
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We have a team of highly skilled IT professionals with extensive experience in various industries. Our team members stay up-to-date with the latest technology trends and advancements to ensure that we deliver cutting-edge solutions tailored to your specific requirements.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Comprehensive Solutions
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We offer a comprehensive range of IT services, covering everything from infrastructure setup and management to cybersecurity, software development, cloud solutions, and more. By choosing us, you can consolidate your IT needs under one roof, saving you time and effort in dealing with multiple service providers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> Customized Approach
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We understand that each business has unique challenges and goals. That's why we take a customized approach to our services. We work closely with you to assess your specific needs, understand your business objectives, and develop tailored IT solutions that align with your vision.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span> Proactive Support and Monitoring
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our proactive approach sets us apart. We continuously monitor your systems, networks, and applications to detect and resolve issues before they impact your operations. We believe in proactive maintenance and strategic planning to minimize downtime, optimize performance, and ensure the smooth functioning of your IT environment.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> Security and Data Protection
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Cybersecurity is a critical concern for businesses today. We prioritize the security of your IT infrastructure and data. Our experts implement robust security measures, including firewalls, encryption, multi-factor authentication, and regular vulnerability assessments, to protect your systems from cyber threats and unauthorized access.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span>Scalability and Flexibility
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      As your business grows and evolves, your IT needs may change. We provide scalable solutions that can adapt to your changing requirements. Whether you need to expand your infrastructure, integrate new software, or migrate to the cloud, we can scale our services accordingly to support your business's growth.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span>Reliable and Timely Support
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We understand that IT issues can occur at any time, disrupting your operations and productivity. Our dedicated support team is available to provide timely assistance and resolve technical issues promptly. We offer various support channels, including phone, email, and ticketing systems, to ensure that your concerns are addressed quickly and efficiently.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span>Commitment to Customer Satisfaction
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Client satisfaction is at the heart of our business. We are committed to building long-term relationships with our clients by delivering exceptional service, maintaining open communication, and consistently exceeding expectations. Your success is our success, and we go the extra mile to ensure that you receive the highest level of service and support.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  {/* rts faq section area End */}
  {/* rts services area start */}
  <div className="rts-service-area rts-section-gapBottom">
    <div className="container-fluid service-main about-service-width-controler">
      <div className="background-service service-three row">
        <div className="row g-5">
          <div className="rts-title-area service-four text-center pt--40 pt_md--0 mt_sm--0 mt_md--0">
            <p className="pre-title">
              Our Services
            </p>
            <h2 className="title">What We Provide</h2>
          </div>
          {/* start single Service */}
          <div className="col-xl-4 col-md-6 col-sm-12 col-12 pt--15 mb--80 mb_md--40 mb_sm--30">
            <div className="service-one-inner-four">
              <div className="big-thumbnail-area">
                <a href="#" className="thumbnail">
                  <img src="assets/images/service/07.jpg" alt="Business-service" />
                </a>
                <div className="content">
                  <img src="assets/images/service/icon/13.svg" alt="Business-icon" />
                  <h5 className="title">Web Designing</h5>
                  <p className="disc">We offer professional web designing services as part of our comprehensive IT solutions.</p>
                </div>
                <Link className="over_link" to={`${process.env.PUBLIC_URL}/web-designing`} />
              </div>
              <Link className="rts-btn btn-primary" to={`${process.env.PUBLIC_URL}/web-designing`} >Read More<i className="fas fa-arrow-right" /></Link>
            </div>
          </div>
          {/* start single Service */}
          <div className="col-xl-4 col-md-6 col-sm-12 col-12 pt--15 mb--80 mb_md--40 mb_sm--30">
            <div className="service-one-inner-four">
              <div className="big-thumbnail-area">
                <a href="#" className="thumbnail">
                  <img src="assets/images/service/08.jpg" alt="Business-service" />
                </a>
                <div className="content">
                  <img src="assets/images/service/icon/14.svg" alt="Business-icon" />
                  <h5 className="title">Digital Marketing</h5>
                  <p className="disc">Digital marketing is a broad term that refers to the promotion of products, services,..</p>
                </div>
                <Link className="over_link" to={`${process.env.PUBLIC_URL}/digital-marketing`} />
              </div>
              <Link className="rts-btn btn-primary" to={`${process.env.PUBLIC_URL}/digital-marketing`} >Read More<i className="fas fa-arrow-right" /></Link>
            </div>
          </div>
          {/* start single Service */}
          <div className="col-xl-4 col-md-6 col-sm-12 col-12 pt--15 mb--80">
            <div className="service-one-inner-four">
              <div className="big-thumbnail-area">
                <a href="#" className="thumbnail">
                  <img src="assets/images/service/09.jpg" alt="Business-service" />
                </a>
                <div className="content">
                  <img src="assets/images/service/icon/15.svg" alt="Business-icon" />
                  <h5 className="title">SEO</h5>
                  <p className="disc">In DN Web Works, we provide comprehensive SEO (Search Engine Optimization) solutions..</p>
                </div>
                <Link className="over_link" to={`${process.env.PUBLIC_URL}/seo`} />
              </div>
              <Link className="rts-btn btn-primary" to={`${process.env.PUBLIC_URL}/seo`} >Read More<i className="fas fa-arrow-right" /></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="cta-one-bg col-12">
          <div className="cta-one-inner">
            <div className="cta-left">
              <h3 className="title animated fadeIn">Let’s discuss about how we can help
                make your business better</h3>
            </div>
            <div className="cta-right">
              <Link className="rts-btn btn-white" to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts services area End */}
  {/* start trusted client section */}
  <div className="rts-trusted-client rts-section-gapBottom">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="title-area-client text-center">
            <p className="client-title">Technologies</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="client-wrapper-one">
          <a href="#"><img src="assets/images/technology/1.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/2.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/3.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/4.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/5.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/6.jpg" alt="business_dnweb" /></a>
        </div>
      </div>
    </div>
  </div>
  {/* end trusted client section */}
</div>

   
    </>
  )
}
