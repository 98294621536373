import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function Pricing() {
  return (
    <>
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-price bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">Our Pricing</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">Our Pricing</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  <section className="dnw-section-gap">
    <div className="container">
      <p className="disc">
        By choosing our web development services, you gain access to a team of skilled developers who are dedicated to delivering high-quality web solutions tailored to your business requirements. Contact us today to discuss your web development needs, and let us help you create a powerful online presence.
      </p>
    </div>
  </section>
  {/* start Pricing section */}
  <section id="pricingSection" className="dnw-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title text-center mt-20">Web Design &amp; Development Pricing in Ludhiana</h2>
          <br />
          <div className="table-responsive">
            <table>
              <tbody><tr>
                  <td className="cell-1 first">
                    <div className="name">Starter Package</div>
                  </td>
                  <td className="cell-2"><div className="name"> Basic Plan </div>
                    <div className="head"> Up to <span> 10 Pages </span> </div></td>
                  <td className="cell-3 highlighted"><div className="name">Standard Plan</div>
                    <div className="head"> Up to <span> 25 Pages </span> </div></td>
                  <td className="cell-4"><div className="name"> Enterprise Plan </div>
                    <div className="head"> <span>Unlimited Pages </span></div></td>
                </tr>
                <tr>
                  <td>Domain Name</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Social Links Intigration</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Free Webb Hosting (1 Year)</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Home &amp; Internal Web Pages</td>
                  <td><span className="gray1">10</span></td>
                  <td><span className="gray1">25</span></td>
                  <td><span className="gray1">Unlimited</span></td>
                </tr>
                <tr>
                  <td>Corporate Email ID's </td>
                  <td><span className="gray1">5 Email ID's</span></td>
                  <td><span className="gray1">10 Email ID's</span></td>
                  <td><span className="gray1">Unlimited Email ID's</span></td>
                </tr>
                <tr>
                  <td>Search Engine Optimization (SEO)</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="gray1">Initial 1 Month SEO</span></td>
                </tr>
                <tr>
                  <td>Contact Form</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Product/Photo Gallery</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Custom Design</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Google Location Map</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Mobile/iPad Compatablility</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Visitor Counter</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>CMS</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Technical Support</td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /> <br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                </tr>
                <tr>
                  <td className="btm-lft">Call Us at<br />
                    <span className="gray-txt"><strong><a href="tel:+917973490508"> +91-79734-90508 </a></strong></span><br />
                    <img src="assets/images/pricing/or.png" width={183} height={18} alt className="or-img" /><br />
                    Email Us at <br />
                    <a href="mailto:dnwebworks@gmail.com"><strong>dnwebworks@gmail.com</strong></a></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 17,999/-</div>
                    <div className="enquire"> <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 23,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 48,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end of pricing section */}
  {/* start Pricing section */}
  <section id="pricingSection" className="dnw-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title text-center mt-20">E-Commerce Pricing in Ludhiana</h2>
          <br />
          <div className="table-responsive">
            <table>
              <tbody><tr>
                  <td className="cell-1 first">
                    {/* <img src="assets/images/pricing/2000-pro.jpg" width="203" height="119" alt="" class="img"> */}
                    <div className="name">Starter Package</div>
                  </td>
                  <td className="cell-2"><div className="name"> Basic Plan </div>
                    <div className="head"> Up to <span> 10 Pages </span> </div></td>
                  <td className="cell-3 highlighted"><div className="name">Standard Plan</div>
                    <div className="head"> Up to <span> 25 Pages </span> </div></td>
                  <td className="cell-4"><div className="name"> Enterprise Plan </div>
                    <div className="head"> <span>Unlimited Pages </span></div></td>
                </tr>
                <tr>
                  <td>Domain Name</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Social Links Intigration</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Free Webb Hosting (1 Year)</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Home &amp; Internal Web Pages</td>
                  <td><span className="gray1">10</span></td>
                  <td><span className="gray1">25</span></td>
                  <td><span className="gray1">Unlimited</span></td>
                </tr>
                <tr>
                  <td>Corporate Email ID's </td>
                  <td><span className="gray1">5 Email ID's</span></td>
                  <td><span className="gray1">15 Email ID's</span></td>
                  <td><span className="gray1">Unlimited Email ID's</span></td>
                </tr>
                <tr>
                  <td>Search Engine Optimization (SEO)</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="gray1">Initial 1 Month SEO</span></td>
                </tr>
                <tr>
                  <td>Contact Form</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Product/Photo Gallery Management</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Custom Design</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Google Location Map</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Mobile/iPad Compatablility</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Visitor Counter</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>CMS</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Shopping Cart</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Payment Gateways</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Technical Support</td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /> <br />
                    for 3 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 6 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                </tr>
                <tr>
                  <td className="btm-lft">Call Us at<br />
                    <span className="gray-txt"><strong><a href="tel:+917973490508"> +91-79734-90508 </a></strong></span><br />
                    <img src="assets/images/pricing/or.png" width={183} height={18} alt className="or-img" /><br />
                    Email Us at <br />
                    <a href="mailto:dnwebworks@gmail.com"><strong>dnwebworks@gmail.com</strong></a></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 29,999/-</div>
                    <div className="enquire"> <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 49,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 95,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end of pricing section */}
  <section className="dnw-section-gap">
    <div className="container">
      <div className="row">
        <div className="cta-one-bg col-12">
          <div className="cta-one-inner">
            <div className="cta-left">
              <h3 className="title animated fadeIn">Become a Part of Our Family
                Speak to a Specialist </h3>
            </div>
            <div className="cta-right">
              <Link className="rts-btn btn-white" to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

    </>
  )
}
