import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function WebDevelopment() {
  return (
    <>
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-wdev bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">Web Development</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">Web Development</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1 img-round" src="assets/images/webdesign/web-design-dnww-1.jpg" alt="BUsiness_image" />
              <img className="img-over img-round" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>Website Development</span>
              <h2 className="title">Best Web Development Services In Ludhiana - DN Web Works</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">As an DN Web Works, we provide comprehensive web development services to help businesses build robust, functional, and dynamic websites that meet their specific needs. Below's an overview of our web development services:</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg ">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">WEB DEVELOPMENT SOLUTIONS</span>
              <h2 className="title">Web Development   
                {/* <span class="sm-title">to Help <span>Businesses</span></span> */}
                <span>Services to Help <span style={{color: '#ff0000'}}>Businesses</span></span>
              </h2>
              <p style={{color: '#ffffff'}}>As an DN Web Works, we offer a range of web development services to help businesses establish a strong online presence and drive their digital success. Our web development services encompass the following:</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span>Front-End Development:
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our front-end developers specialize in creating visually appealing and user-friendly interfaces. Using HTML, CSS, and JavaScript frameworks such as React, Angular, or Vue.js, we develop responsive and interactive websites that deliver an exceptional user experience across different devices and browsers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Back-End Development:
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our back-end developers focus on building the server-side functionality of your website. We work with various programming languages like Python, PHP, Java, or Node.js, and leverage frameworks such as Django, Laravel, Spring, or Express.js to develop powerful and scalable web applications that handle data processing, database interactions, and business logic.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> Full-Stack Development:
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      If you require end-to-end web development expertise, our full-stack developers can handle both the front-end and back-end development aspects. With proficiency in multiple technologies, they ensure seamless integration between the client-side and server-side components, delivering comprehensive and cohesive web solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span> Database Integration:
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We specialize in integrating databases into web applications to handle data storage and retrieval efficiently. Whether it's SQL-based databases like MySQL or PostgreSQL or NoSQL databases like MongoDB or Firebase, our developers have the expertise to design and implement secure and optimized database architectures.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> Content Management Systems (CMS):
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We work with popular CMS platforms like WordPress, Drupal, Joomla, or custom CMS solutions to provide you with a user-friendly interface to manage and update your website content easily. We can customize and extend the CMS functionalities to meet your specific requirements and ensure seamless content publishing.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span> E-commerce Development:
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our team has experience in building secure and feature-rich e-commerce websites. We leverage platforms like WooCommerce, Magento, Shopify, or develop custom e-commerce solutions to create online stores with functionalities such as product catalogs, shopping carts, secure payment gateways, inventory management, and order processing.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span>Third-Party API Integration:
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We integrate third-party APIs to enhance the functionality of your website. Whether it's integrating payment gateways, social media platforms, mapping services, or any other API, we ensure smooth and secure integration to provide a seamless user experience and extend the capabilities of your web application.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span> Testing and Quality Assurance:
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We conduct comprehensive testing and quality assurance processes to ensure that your web application functions flawlessly. Our team performs unit testing, integration testing, performance testing, security testing, and user acceptance testing to identify and resolve any bugs or issues before deployment.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingnouw">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenouw" aria-expanded="false" aria-controls="collapsenouw">
                      <span>09. </span> Web Maintenance and Support:
                    </button>
                  </h2>
                  <div id="collapsenouw" className="accordion-collapse collapse" aria-labelledby="headingnouw" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We offer ongoing maintenance and support services to keep your website running smoothly. We provide updates, security patches, bug fixes, performance optimizations, and technical support to ensure that your web application remains secure, up to date, and performs optimally.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  <section className="dnw-section-gap">
    <div className="container">
      <p className="disc">
        By choosing our web development services, you gain access to a team of skilled developers who are dedicated to delivering high-quality web solutions tailored to your business requirements. Contact us today to discuss your web development needs, and let us help you create a powerful online presence.
      </p>
    </div>
  </section>
  {/* start Pricing section */}
  <section id="pricingSection" className="dnw-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title text-center mt-20">Web Development Pricing in Ludhiana</h2>
          <br />
          <div className="table-responsive">
            <table>
              <tbody><tr>
                  <td className="cell-1 first">
                    <div className="name">Starter Package</div>
                  </td>
                  <td className="cell-2"><div className="name"> Basic Plan </div>
                    <div className="head"> Up to <span> 10 Pages </span> </div></td>
                  <td className="cell-3 highlighted"><div className="name">Standard Plan</div>
                    <div className="head"> Up to <span> 25 Pages </span> </div></td>
                  <td className="cell-4"><div className="name"> Enterprise Plan </div>
                    <div className="head"> <span>Unlimited Pages </span></div></td>
                </tr>
                <tr>
                  <td>Domain Name</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Social Links Intigration</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Free Webb Hosting (1 Year)</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Home &amp; Internal Web Pages</td>
                  <td><span className="gray1">10</span></td>
                  <td><span className="gray1">25</span></td>
                  <td><span className="gray1">Unlimited</span></td>
                </tr>
                <tr>
                  <td>Corporate Email ID's </td>
                  <td><span className="gray1">5 Email ID's</span></td>
                  <td><span className="gray1">10 Email ID's</span></td>
                  <td><span className="gray1">Unlimited Email ID's</span></td>
                </tr>
                <tr>
                  <td>Search Engine Optimization (SEO)</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="gray1">Initial 1 Month SEO</span></td>
                </tr>
                <tr>
                  <td>Contact Form</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Product/Photo Gallery</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Custom Design</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Google Location Map</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Mobile/iPad Compatablility</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Visitor Counter</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>CMS</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Technical Support</td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /> <br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                </tr>
                <tr>
                  <td className="btm-lft">Call Us at<br />
                    <span className="gray-txt"><strong><a href="tel:+917973490508"> +91-79734-90508 </a></strong></span><br />
                    <img src="assets/images/pricing/or.png" width={183} height={18} alt className="or-img" /><br />
                    Email Us at <br />
                    <a href="mailto:dnwebworks@gmail.com"><strong>dnwebworks@gmail.com</strong></a></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 17,999/-</div>
                    <div className="enquire"> <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 23,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 48,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end of pricing section */}
</div>

    </>
  )
}
