import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function Seo() {
  return (
    <>
        
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-seo bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">SEO</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">SEO</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1 img-round" src="assets/images/webdesign/web-design-dnww-1.jpg" alt="BUsiness_image" />
              <img className="img-over img-round" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>Search Engine Optimization</span>
              <h2 className="title">SEO Services in Ludhiana</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">In DN Web Works, we provide comprehensive SEO (Search Engine Optimization) solutions to help businesses improve their online visibility, increase organic traffic, and achieve higher rankings in search engine results. Our SEO solutions encompass a wide range of strategies and techniques to ensure maximum effectiveness. Below's an overview of our SEO solutions:</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg ">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">SEARCH ENGINE OPTIMIZATION</span>
              <h2 className="title">SEO   
                {/* <span class="sm-title">to Help <span>Businesses</span></span> */}
                <span>Services to Help <span style={{color: '#ff0000'}}>Businesses</span></span>
              </h2>
              <p style={{color: '#ffffff'}}><strong>DN Web Works</strong> offering SEO services typically provides a range of solutions to optimize a website's performance and improve its search engine rankings. Some common SEO services offered by <strong>DN Web Works</strong> include:</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span>SEO Audit and Analysis:
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We conduct a detailed SEO audit to assess your website's current performance and identify areas that need improvement. Our analysis includes evaluating on-page factors, site structure, technical aspects, backlink profile, and competitor analysis. This audit serves as the foundation for developing a customized SEO strategy.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Keyword Research and Optimization:
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We perform extensive keyword research to identify relevant and high-value keywords that your target audience is using to search for products or services similar to yours. We optimize your website's content, meta tags, headings, URLs, and other on-page elements to incorporate these keywords effectively.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> On-Page Optimization:
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our team optimizes various on-page elements of your website to make them more search engine-friendly. This includes optimizing title tags, meta descriptions, header tags, URL structures, and internal linking. We also optimize the content on your website to ensure it is relevant, engaging, and valuable to both search engines and users.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span>Technical SEO:
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We address technical aspects of your website that affect its search engine visibility. This involves optimizing website speed and performance, ensuring proper indexing and crawling, fixing broken links and redirects, implementing structured data markup, and improving site architecture. We also optimize your website for mobile devices to enhance the user experience and search engine rankings.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> Content Marketing:
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We develop a comprehensive content marketing strategy to create and distribute high-quality, relevant, and engaging content. This includes blog posts, articles, infographics, videos, and other forms of content that provide value to your target audience. Our content marketing efforts are aimed at attracting organic traffic, increasing brand awareness, and earning valuable backlinks.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span> Link Building: 
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We employ white-hat link building techniques to enhance your website's authority and credibility. Our team identifies authoritative and relevant websites within your industry and acquires high-quality backlinks through guest posting, influencer outreach, content partnerships, and other ethical link building strategies.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span> Local SEO: 
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      If your business has a local presence, we optimize your website for local search results. This includes optimizing your Google My Business profile, creating and optimizing location-specific landing pages, managing online reviews and ratings, and improving local citations and directories.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span> SEO Reporting and Analytics:
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We provide regular reports and analytics to track the performance of your SEO campaigns. Our reports include key metrics such as organic traffic, keyword rankings, backlink analysis, conversion rates, and ROI. We use these insights to measure the effectiveness of our strategies and make data-driven optimizations.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingnouw">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenouw" aria-expanded="false" aria-controls="collapsenouw">
                      <span>09. </span>  SEO Consulting and Strategy:
                    </button>
                  </h2>
                  <div id="collapsenouw" className="accordion-collapse collapse" aria-labelledby="headingnouw" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We offer SEO consulting services to help you develop a solid SEO strategy aligned with your business goals. Our experts provide guidance on SEO best practices, industry trends, and help you make informed decisions to optimize your website's performance.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingtaan">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetaan" aria-expanded="false" aria-controls="collapsetaan">
                      <span>10. </span>  Ongoing SEO Monitoring and Maintenance:
                    </button>
                  </h2>
                  <div id="collapsetaan" className="accordion-collapse collapse" aria-labelledby="headingtaan" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We continuously monitor the performance of your website in search engine rankings, track algorithm updates, and make necessary adjustments to your SEO strategies. We provide ongoing maintenance to ensure your website remains optimized and competitive.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  <section className="dnw-section-gap">
    <div className="container">
      <p className="disc">
        By choosing our SEO solutions, you can leverage our expertise in improving your website's visibility, driving organic traffic, and achieving higher rankings in search engine results. Contact us today to discuss your SEO needs and let us help you achieve your online marketing objectives.
      </p>
    </div>
  </section>
</div>

  
    </>
  )
}
