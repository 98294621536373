import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function WebDesigning() {
  return (
    <>
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-wd bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">Web Designing</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">Web Designing</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1 img-round" src="assets/images/webdesign/web-design-dnww-1.jpg" alt="BUsiness_image" />
              <img className="img-over img-round" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>Website Designing</span>
              <h2 className="title">Best Website Designing Company In Ludhiana - DN Web Works</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">We offer professional web designing services as part of our comprehensive IT solutions. Our web design services are tailored to meet the specific needs of businesses looking to establish a strong online presence and engage their target audience effectively. Below's an overview of our web designing services:</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg ">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">WEB DESIGNING SOLUTIONS</span>
              <h2 className="title">Web Designing   
                {/* <span class="sm-title">to Help <span>Businesses</span></span> */}
                <span>Services to Help <span style={{color: '#ff0000'}}>Businesses</span></span>
              </h2>
              <p style={{color: '#ffffff'}}>As an DN Web Works, we offer a range of web designing services to help businesses establish a strong online presence and drive their digital success. Our web designing services encompass the following:</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span>Custom Website Design:
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We understand that your website is often the first impression customers have of your business. Our team of skilled web designers will work closely with you to create a custom website that reflects your brand identity, captures your unique value proposition, and provides a user-friendly experience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Responsive Design:
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      With the increasing use of mobile devices, it is crucial to have a website that is responsive and adapts seamlessly to different screen sizes. Our web design team ensures that your website is mobile-friendly, providing an optimal user experience on smartphones, tablets, and desktops.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> User Experience (UX) Design:
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We focus on creating websites that are intuitive and user-friendly. Through thoughtful UX design, we enhance the usability and accessibility of your website, making it easy for visitors to navigate, find information, and take desired actions, such as making a purchase or submitting a contact form.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span> Visual Appeal and Branding:
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We incorporate visually appealing elements, such as engaging layouts, high-quality images, fonts, and color schemes, to create a visually compelling website that aligns with your brand identity. Our goal is to ensure that your website stands out and leaves a lasting impression on visitors.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> Content Management System (CMS) Integration:
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We can integrate a user-friendly CMS into your website, empowering you to manage and update your website content without the need for technical expertise. We work with popular CMS platforms such as WordPress, Drupal, and Joomla, providing you with a flexible and scalable solution.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span>Search Engine Optimization (SEO) Considerations:
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our web design process includes incorporating SEO best practices to optimize your website for search engines. We ensure that your website has clean code, proper meta tags, optimized page load speed, and a structure that search engines can easily crawl and index, helping improve your organic search rankings.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span>E-commerce Website Design:
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      If you plan to sell products or services online, we can develop an e-commerce website that integrates secure payment gateways, shopping carts, inventory management, and order processing systems. Our e-commerce solutions are designed to enhance the user experience, streamline the buying process, and maximize conversions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span>Ongoing Support and Maintenance:
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We offer reliable support and maintenance services to keep your website running smoothly. Whether you need routine updates, security patches, or assistance with technical issues, our team is available to provide timely support and ensure that your website remains secure and up to date.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  {/* start Pricing section */}
  <section id="pricingSection" className="rts-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title text-center mt-20">Web Designing Pricing in Ludhiana</h2>
          <br />
          <div className="table-responsive">
            <table>
              <tbody><tr>
                  <td className="cell-1 first">
                    {/* <img src="assets/images/pricing/2000-pro.jpg" width="203" height="119" alt="" class="img"> */}
                    <div className="name">Starter Package</div>
                  </td>
                  <td className="cell-2"><div className="name"> Basic Plan </div>
                    <div className="head"> Up to <span> 10 Pages </span> </div></td>
                  <td className="cell-3 highlighted"><div className="name">Standard Plan</div>
                    <div className="head"> Up to <span> 25 Pages </span> </div></td>
                  <td className="cell-4"><div className="name"> Enterprise Plan </div>
                    <div className="head"> <span>Unlimited Pages </span></div></td>
                </tr>
                <tr>
                  <td>Domain Name</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Social Links Intigration</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Free Webb Hosting (1 Year)</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Home &amp; Internal Web Pages</td>
                  <td><span className="gray1">10</span></td>
                  <td><span className="gray1">25</span></td>
                  <td><span className="gray1">Unlimited</span></td>
                </tr>
                <tr>
                  <td>Corporate Email ID's </td>
                  <td><span className="gray1">3 Email ID's</span></td>
                  <td><span className="gray1">10 Email ID's</span></td>
                  <td><span className="gray1">Unlimited Email ID's</span></td>
                </tr>
                <tr>
                  <td>Search Engine Optimization (SEO)</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="gray1">Initial 1 Month SEO</span></td>
                </tr>
                <tr>
                  <td>Contact Form</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Product/Photo Gallery</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Custom Design</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Google Location Map</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Mobile/iPad Compatablility</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Visitor Counter</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>CMS</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Technical Support</td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /> <br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                </tr>
                <tr>
                  <td className="btm-lft">Call Us at<br />
                    <span className="gray-txt"><strong><a href="tel:+917973490508"> +91-79734-90508 </a></strong></span><br />
                    <img src="assets/images/pricing/or.png" width={183} height={18} alt className="or-img" /><br />
                    Email Us at <br />
                    <a href="mailto:dnwebworks@gmail.com"><strong>dnwebworks@gmail.com</strong></a></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 17,999/-</div>
                    <div className="enquire"> <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 23,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 48,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end of pricing section */}
</div>

    </>
  )
}
