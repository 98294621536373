import React from 'react'

export default function Loader() {
  return (
    <>
        {/* start loader */}
<div className="loader-wrapper">
    <div className="loader">
    </div>
    <div className="loader-section section-left" />
    <div className="loader-section section-right" />
  </div>
  {/* End loader */}
    </>
  )
}
