import React from 'react';

import {  Link } from "react-router-dom";

function refreshPage() {
  setTimeout(()=>{
      window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function Sidebar() {
  return (
    <>
         <div id="side-bar" className="side-bar">
    <button className="close-icon-menu"><i className="fas fa-times" /></button>
    {/* inner menu area desktop start */}
    <div className="rts-sidebar-menu-desktop">
      <Link className="logo-1" to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}><img  className="logo" src="assets/images/logo/logo-1.png" alt="dnweb-logo" /></Link>
      <div className="body d-none d-xl-block">
        <p className="disc">
          DN Web Works was built on the idea that the world is better when businesses and customers communicate freely.
        </p>
        <div className="get-in-touch">
          {/* title */}
          <div className="h6 title">Get In Touch</div>
          {/* title End */}
          <div className="wrapper">
            {/* single */}
            <div className="single">
              <i className="fas fa-phone" />
              <a href="tel:+917973490508">+91 797.349.0508</a>
            </div>
            {/* single ENd */}
            {/* single */}
            <div className="single">
              <i className="fas fa-envelope" />
              <a href="mailto:dnwebworks@gmail.com">dnwebworks@gmail.com</a>
            </div>
            {/* single ENd */}
            {/* single */}
            <div className="single">
              <i className="fas fa-globe" />
              <a href="https://dnwebworks.com/">www.dnwebworks.com</a>
            </div>
            {/* single ENd */}
            {/* single */}
            <div className="single">
              <i className="fas fa-map-marker-alt" />
              <a href="https://goo.gl/maps/TKhGAniDaWuWjk8Q6">Near Amrit Dharam Kanda, Tajpur Road
                Ludhiana,Punjab INDIA</a>
            </div>
            {/* single ENd */}
          </div>
          <div className="social-wrapper-two menu">
            <a href="https://www.facebook.com/dnwebworks" target="_blank"><i className="fab fa-facebook-f" /></a>
            <a href="https://twitter.com/dnwebworks" target="_blank"><i className="fab fa-twitter" /></a>
            <a href="https://www.instagram.com/dnwebworks/" target="_blank"><i className="fab fa-instagram" /></a>
            <a href="https://api.whatsapp.com/send?phone=918288012437&text=Hello this is the starting message DN Web Works." target="_blank"><i className="fab fa-whatsapp" /></a>
            {/* <a href="#"><i class="fab fa-linkedin"></i></a> */}
          </div>
        </div>
      </div>
      <div className="body-mobile d-block d-xl-none">
        <nav className="nav-main mainmenu-nav">
          <ul className="mainmenu">
            <li className="menu-item menu-item">
            <Link className="menu-link" to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            </li>
            <li className="menu-item menu-item">
            <Link className="menu-link" to={`${process.env.PUBLIC_URL}/about-us`} onClick={refreshPage}>About Us</Link>
            </li>
            <li className="menu-item menu-item">
            <Link className="menu-link" to={`${process.env.PUBLIC_URL}/it-solutions`} onClick={refreshPage}>IT Solutions</Link>
            </li>
            <li  className="has-droupdown menu-item">
              <Link className="menu-link" to="#">Our Services</Link>
              <ul className="submenu active">
                <li className="mobile-menu-link">
                <Link  to={`${process.env.PUBLIC_URL}/web-designing`} onClick={refreshPage}>Web Designing</Link>
                </li>
                <li className="mobile-menu-link">
                <Link  to={`${process.env.PUBLIC_URL}/web-development`} onClick={refreshPage}>Web Development</Link>
                </li>
                <li className="mobile-menu-link">
                <Link  to={`${process.env.PUBLIC_URL}/graphic-designing`} onClick={refreshPage}>Graphic Designing</Link>
                </li>
                <li className="mobile-menu-link">
                <Link  to={`${process.env.PUBLIC_URL}/e-commerce-solutions`} onClick={refreshPage}>E-commerce Soloutions</Link>
                </li>
                <li className="mobile-menu-link">
                <Link  to={`${process.env.PUBLIC_URL}/seo`}>SEO</Link>
                </li>
                <li className="mobile-menu-link">
                <Link  to={`${process.env.PUBLIC_URL}/digital-marketing`} onClick={refreshPage}>Digital Marketing</Link>
                </li>
              </ul>
            </li>
            <li className="menu-item">
            <Link className="menu-link" to={`${process.env.PUBLIC_URL}/pricing`} onClick={refreshPage}>Pricing</Link>
            </li>
            <li className="menu-item menu-item">
            <Link className="menu-link" to={`${process.env.PUBLIC_URL}/contact-us`} onClick={refreshPage}>Contact</Link>
            </li>
          </ul>
        </nav>
        <div className="social-wrapper-two menu mobile-menu">
          <a href="https://www.facebook.com/dnwebworks" target="_blank"><i className="fab fa-facebook-f" /></a>
          <a href="https://twitter.com/dnwebworks" target="_blank"><i className="fab fa-twitter" /></a>
          <a href="https://www.instagram.com/dnwebworks/" target="_blank"><i className="fab fa-instagram" /></a>
          <a href="https://api.whatsapp.com/send?phone=918288012437&text=Hello this is the starting message DN Web Works." target="_blank"><i className="fab fa-whatsapp" /></a>
          {/* <a href="#"><i class="fab fa-linkedin"></i></a> */}
        </div>
        <a href="#" className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btnmenu">Get Quote</a>
      </div>
    </div>
    {/* inner menu area desktop End */}
  </div>
    </>
  )
}
