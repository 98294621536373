import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function GraphicDesigning() {
  return (
    <>
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-gd bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">Graphic Designing</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">Graphic Designing</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1 img-round" src="assets/images/webdesign/web-design-dnww-1.jpg" alt="BUsiness_image" />
              <img className="img-over img-round" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>Graphic Designing</span>
              <h2 className="title">Best Graphic Designing Services In Ludhiana - DN Web Works</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">As an DN Web Works, we provide comprehensive graphic design services to help businesses enhance their visual communication and branding. Below's an overview of our Graphic designing services:</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg ">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">GRAPHIC DESIGNING SOLUTIONS</span>
              <h2 className="title">Graphic Designing   
                {/* <span class="sm-title">to Help <span>Businesses</span></span> */}
                <span>Services to Help <span style={{color: '#ff0000'}}>Businesses</span></span>
              </h2>
              <p style={{color: '#ffffff'}}>Our graphic design services include::</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span>Logo Design:
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We specialize in creating unique and impactful logos that represent your brand identity. Our team of graphic designers collaborates with you to understand your brand values, target audience, and design preferences, crafting a logo that resonates with your brand and leaves a lasting impression.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Brand Identity Design:
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We develop cohesive brand identities that encompass visual elements such as color schemes, typography, imagery, and graphic elements. Our designers work closely with you to create a consistent and professional brand image across all marketing materials, including business cards, letterheads, brochures, and more.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> Print Design:
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We offer print design services for various collateral, including brochures, flyers, posters, banners, and packaging. Our designers combine creativity with an understanding of your brand's objectives and target audience, creating visually appealing and engaging print materials that effectively convey your message.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span>Web Design Graphics:
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our graphic designers work closely with our web development team to create visually compelling graphics for websites. From creating custom illustrations and icons to designing visually appealing banners, sliders, and other web elements, we ensure that your website is visually appealing, on-brand, and engaging.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> UI/UX Design:
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      User interface (UI) and user experience (UX) design play a crucial role in creating intuitive and user-friendly digital products. Our graphic designers collaborate with our UX/UI designers to create visually pleasing and functional interfaces that provide an optimal user experience across different platforms and devices.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span>Social Media Graphics:
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We design customized graphics for social media platforms, including cover images, profile pictures, post graphics, and social media ads. Our designers ensure that the graphics align with your brand identity, effectively communicate your message, and attract engagement from your target audience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span> Infographic Design:
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We create visually compelling infographics that present complex information and data in a concise and engaging manner. Our designers have expertise in transforming data into visually appealing graphics, making it easier for your audience to understand and remember key information.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span>Visual Content Creation:
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Visual Content Creation: Our graphic design services extend to visual content creation, including illustrations, icons, and custom graphics. Whether you need unique illustrations for your website, icons for your mobile app, or custom graphics for your marketing materials, our designers can bring your ideas to life.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingnouw">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenouw" aria-expanded="false" aria-controls="collapsenouw">
                      <span>09. </span>  Brand Style Guides:
                    </button>
                  </h2>
                  <div id="collapsenouw" className="accordion-collapse collapse" aria-labelledby="headingnouw" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We can develop comprehensive brand style guides that outline guidelines for the usage of your brand's visual elements. This ensures consistency across all marketing materials and helps maintain a strong and cohesive brand image.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  <section className="dnw-section-gap">
    <div className="container">
      <p className="disc">
        By choosing our graphic design services, you can benefit from the expertise of our skilled designers who will help you create visually stunning and impactful designs that align with your brand identity and effectively communicate your message. Contact us today to discuss your graphic design needs, and let us enhance your visual communication and branding efforts.
      </p>
    </div>
  </section>
  {/* start Pricing section */}
  <section id="pricingSection" className="dnw-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="section-title text-center mt-20">Web Designing Pricing in Ludhiana</h2>
          <br />
          <div className="table-responsive">
            <table>
              <tbody><tr>
                  <td className="cell-1 first">
                    {/* <img src="assets/images/pricing/2000-pro.jpg" width="203" height="119" alt="" class="img"> */}
                    <div className="name">Starter Package</div>
                  </td>
                  <td className="cell-2"><div className="name"> Basic Plan </div>
                    <div className="head"> Up to <span> 10 Pages </span> </div></td>
                  <td className="cell-3 highlighted"><div className="name">Standard Plan</div>
                    <div className="head"> Up to <span> 25 Pages </span> </div></td>
                  <td className="cell-4"><div className="name"> Enterprise Plan </div>
                    <div className="head"> <span>Unlimited Pages </span></div></td>
                </tr>
                <tr>
                  <td>Domain Name</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Social Links Intigration</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Free Webb Hosting (1 Year)</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Home &amp; Internal Web Pages</td>
                  <td><span className="gray1">10</span></td>
                  <td><span className="gray1">25</span></td>
                  <td><span className="gray1">Unlimited</span></td>
                </tr>
                <tr>
                  <td>Corporate Email ID's </td>
                  <td><span className="gray1">3 Email ID's</span></td>
                  <td><span className="gray1">10 Email ID's</span></td>
                  <td><span className="gray1">Unlimited Email ID's</span></td>
                </tr>
                <tr>
                  <td>Search Engine Optimization (SEO)</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="gray1">Initial 1 Month SEO</span></td>
                </tr>
                <tr>
                  <td>Contact Form</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Product/Photo Gallery</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Custom Design</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Google Location Map</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Mobile/iPad Compatablility</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Visitor Counter</td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>CMS</td>
                  <td><span className="red1"><i className="fa fa-times" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                  <td><span className="green1"><i className="fa fa-check" aria-hidden="true" /></span></td>
                </tr>
                <tr>
                  <td>Technical Support</td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /> <br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                  <td><img src="assets/images/pricing/free-tat.png" width={39} height={25} alt /><br />
                    for 12 months </td>
                </tr>
                <tr>
                  <td className="btm-lft">Call Us at<br />
                    <span className="gray-txt"><strong><a href="tel:+917973490508"> +91-79734-90508 </a></strong></span><br />
                    <img src="assets/images/pricing/or.png" width={183} height={18} alt className="or-img" /><br />
                    Email Us at <br />
                    <a href="mailto:dnwebworks@gmail.com"><strong>dnwebworks@gmail.com</strong></a></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 17,999/-</div>
                    <div className="enquire"> <Link to={`${process.env.PUBLIC_URL}/contact-us`}>Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 23,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                  <td className="btm-cells"><div className="oreder-div">Rs. 48,999/-</div>
                    <div className="enquire"><Link to={`${process.env.PUBLIC_URL}/contact-us`}> Request for Call Back</Link></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end of pricing section */}
</div>

    </>
  )
}
