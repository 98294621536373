import React from 'react'

export default function RightButton() {
  return (
    <>
        <div className="cp-widget-button">
    <div data-brand-color="background" className="cp-widget-button__inner"><a href="https://api.whatsapp.com/send?phone=918288012437&text=Hello this is the starting message DN Web Works."><img src="assets/images/whatsapp.png" /></a></div>
  </div>
  <div className="cp-widget-button1">
    <div data-brand-color="background" className="cp-widget-button__inner1"><a href="tel:+917973490508"><img src="assets/images/call.png" /></a></div>
  </div>
    </>
  )
}
