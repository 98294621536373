import React, { Component } from 'react'
import CopyRightReserve from './CopyRightReserve'
import QuikLinks from './QuikLinks'
import ActionArea from './ActionArea'
import TimeTable from './TimeTable'
import AddressCol from './AddressCol'

export default class Footer extends Component {
    render() {
        return (
            <>
  <footer className="rts-footer-area footer-one rts-section-gapTop bg-footer-one">
    <div className="container bg-shape-f1">
    <ActionArea/>
    <div className="row pt--120 pt_sm--80 pb--80 pb_sm--40">
        <QuikLinks/>
        <TimeTable/>
       <AddressCol/>
      </div>
    </div>{/* .container end */}
    <CopyRightReserve/>
  </footer>{/* #footer end */}
 
            </>
        )
    }
}
