import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function ITSolutions() {
  return (
   <>
    
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-it-sol bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">IT Solutions</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">IT Solutions</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1" src="assets/images/about/main/about-03.jpg" alt="BUsiness_image" />
              <img className="img-over" src="assets/images/about/main/about-04.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>IT SOLUTIONS</span>
              <h2 className="title">Get to Now Best <span style={{color: '#ff0000', fontWeight: 800, fontSize: 48}}>IT Solutions</span> in DN Web Works</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">IT solutions are comprehensive packages or services that address specific information technology needs or challenges faced by businesses or organizations. These solutions are designed to improve efficiency, enhance productivity, streamline processes, and meet the technological requirements of an organization.</p>
              <p className="disc">
                These are just some examples of IT solutions. The specific needs of an organization may vary depending on its industry, size, and objectives. DN Web Works solution providers work closely with organizations to understand their requirements and tailor solutions accordingly.</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts about us section end */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-12">
          <div className="about-progress-inner-dn">
            {/* inner start */}
            <div className="inner">
              <div className="accordion-service-inner inner-dn">
                <div className="title-area-start">
                  <span className="sub color-primary">You can expect a reliable and innovative IT partner</span>
                  <h2 className="title">IT solutions can encompass a wide range of areas within the IT field. Some common areas include:</h2>
                </div>
                <div className="accordion-area">
                  <div className="accordion" id="accordionExample3">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                          Infrastructure Solutions:
                        </button>
                      </h2>
                      <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample3" style={{}}>
                        <div className="accordion-body">
                          This includes hardware, software, and network solutions that form the foundation of an organization's IT environment. It may involve designing and implementing server systems, network architecture, storage solutions, virtualization, and cloud infrastructure.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          Security Solutions:
                        </button>
                      </h2>
                      <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample3" style={{}}>
                        <div className="accordion-body">
                          IT security solutions focus on protecting an organization's data, networks, and systems from various threats. This includes implementing firewalls, antivirus software, intrusion detection systems, data encryption, access controls, and security audits.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                          Data Management Solutions:
                        </button>
                      </h2>
                      <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          These solutions involve managing and organizing an organization's data effectively. It may include database management systems, data backup and recovery solutions, data integration tools, data governance, and analytics platforms.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                          Software Solutions:
                        </button>
                      </h2>
                      <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          IT solutions related to software development, deployment, and management. This includes custom software development, enterprise resource planning (ERP) systems, customer relationship management (CRM) software, content management systems, and other specialized applications.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                          Collaboration and Communication Solutions:
                        </button>
                      </h2>
                      <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          These solutions focus on improving communication and collaboration within an organization. It may include email systems, instant messaging platforms, video conferencing tools, project management software, and document sharing platforms.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingten">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                          Cloud Solutions:
                        </button>
                      </h2>
                      <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          Cloud-based solutions offer on-demand access to computing resources and services over the internet. This includes infrastructure as a service (IaaS), platform as a service (PaaS), and software as a service (SaaS) solutions, allowing organizations to scale resources, reduce costs, and increase flexibility.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingelvn">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseelvn" aria-expanded="false" aria-controls="collapseten">
                          IT Service Management (ITSM):
                        </button>
                      </h2>
                      <div id="collapseelvn" className="accordion-collapse collapse" aria-labelledby="headingelvn" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          ITSM solutions help organizations manage and deliver IT services effectively. It includes ITIL (Information Technology Infrastructure Library) practices, service desk software, incident management, change management, and asset management tools.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingtwelv">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwelv" aria-expanded="false" aria-controls="collapsetwelv">
                          Business Intelligence and Analytics:
                        </button>
                      </h2>
                      <div id="collapsetwelv" className="accordion-collapse collapse" aria-labelledby="headingtwelv" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          Solutions that enable organizations to extract insights from their data, make data-driven decisions, and gain a competitive edge. This includes data visualization tools, reporting systems, predictive analytics, and machine learning solutions.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingthirten">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethirten" aria-expanded="false" aria-controls="collapsethirten">
                          Mobile Solutions:
                        </button>
                      </h2>
                      <div id="collapsethirten" className="accordion-collapse collapse" aria-labelledby="headingthirten" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          IT solutions designed for mobile devices, such as mobile app development, mobile device management (MDM), mobile security, and mobile application testing.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfroten">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefroten" aria-expanded="false" aria-controls="collapsefroten">
                          IT Consulting:
                        </button>
                      </h2>
                      <div id="collapsefroten" className="accordion-collapse collapse" aria-labelledby="headingfroten" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          Consulting services provided by IT professionals to assess an organization's IT infrastructure, align technology with business objectives, recommend improvements, and provide strategic guidance.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="disc mt-5 mb-3">
                  By choosing DN Web Works, you can expect a reliable and innovative IT partner who will work alongside you to achieve your business objectives. We are passionate about technology and dedicated to helping your business thrive in the digital age.</p>
                <p className="disc">
                  Contact us today to discuss your IT needs and let us show you how our services can empower your business for success.
                </p>
              </div>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* start trusted client section */}
  <div className="rts-trusted-client rts-section-gapBottom">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="title-area-client text-center">
            <p className="client-title">Technologies</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="client-wrapper-one">
          <a href="#"><img src="assets/images/technology/1.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/2.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/3.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/4.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/5.jpg" alt="business_dnweb" /></a>
          <a href="#"><img src="assets/images/technology/6.jpg" alt="business_dnweb" /></a>
        </div>
      </div>
    </div>
  </div>
  {/* end trusted client section */}
</div>

   </>
  )
}
