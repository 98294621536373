import React from 'react';
import {  Link } from "react-router-dom";
function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 0);
  // console.log('page to reload')
}

export default function DigitalMarketing() {
  return (
    <>
        
<div>
  <div id="anywhere-home">
  </div>
  {/* ENd Header Area */}
  {/* start breadcrumb area */}
  <div className="rts-breadcrumb-area breadcrumb-bg-dg bg_image">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
          <h1 className="title">Digital Marketing</h1>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bread-tag">
          <Link  to={`${process.env.PUBLIC_URL}/`} onClick={refreshPage}>Home</Link>
            <span> / </span>
            <a href="#" className="active">Digital Marketing</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end breadcrumb area */}
  {/* rts about us section start */}
  <div className="rts-about-area dnw-section-gap">
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-lg-6">
          <div className="about-image-v-inner">
            <div className="image-area">
              <img className="mt--110 img-1 img-round" src="assets/images/webdesign/web-design-dnww-1.jpg" alt="BUsiness_image" />
              <img className="img-over img-round" src="assets/images/webdesign/web-design-dnww-2.jpg" alt="BUsiness_image" />
              {/* <div class="goal-button-wrapper">
                      <div class="vedio-icone">
                          <a id="play-video" class="video-play-button" href="#">
                              <span></span>
                          </a>
                          <div id="video-overlay" class="video-overlay">
                              <a class="video-overlay-close">×</a>
                          </div>
                      </div>
                  </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-progress-inner">
            <div className="title-area">
              <span>DIGITAL MARKETING</span>
              <h2 className="title">Best Digital Marketing Services in Ludhiana</h2>
            </div>
            {/* inner start */}
            <div className="inner">
              <p className="disc">Digital marketing is a broad term that refers to the promotion of products, services, or brands using digital channels and technologies. It encompasses various online marketing strategies and tactics to reach and engage a target audience, build brand awareness, drive website traffic, generate leads, and ultimately increase conversions and sales.</p>
              {/* <div class="rts-progress-one-wrapper">
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Business Strategy</p>
                              <span class="persectage">70%</span>
                          </div>
                          <div class="meter cadetblue">
                              <span data-progress="70" style="width:0;"></span>
                          </div>
                      </div>
                      <div class="single-progress">
                          <div class="progress-top">
                              <p class="progress-title">Company Strength</p>
                              <span class="persectage">93%</span>
                          </div>
                          <div class="meter">
                              <span data-progress="93" style="width:0;"></span>
                          </div>
                      </div>
                  </div> */}
              <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="rts-btn btn-primary">Contact Us</Link>
            </div>
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* rts faq section area */}
  <div className="rts-faq-section rts-section-gap rts-faq-bg ">
    <div className="container">
      <div className="row align-items-center">
        <div className="faq-two-inner-dn">
        </div>
        <div className="col-lg-12">
          <div className="faq-two-inner">
            <div className="title-area-faq">
              <span className="sub">DIGITAL MARKETING</span>
              <h2 className="title">Digital marketing   
                {/* <span class="sm-title">to Help <span>Businesses</span></span> */}
                <span>Services to Help <span style={{color: '#ff0000'}}>Businesses</span></span>
              </h2>
              <p style={{color: '#ffffff'}}><strong>DN Web Works</strong> Digital marketing services provided by an DN Web Works can vary depending on their specialization and expertise. Here are some common digital marketing services offered by <strong>DN Web Works</strong>:</p>
            </div>
            {/* faq accordion area */}
            <div className="faq-accordion-area">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>01. </span>Search Engine Optimization (SEO):
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      DN Web Works can help optimize your website to improve its visibility in search engine results. They can conduct keyword research, optimize website content, and implement technical SEO strategies to enhance organic search rankings.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span>02. </span> Pay-Per-Click (PPC) Advertising:
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can create and manage PPC campaigns on platforms like Google Ads and Bing Ads. They can help you set up targeted ads, select relevant keywords, and optimize your ad campaigns for better conversions and ROI.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>03. </span> Social Media Marketing:
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can develop and implement social media marketing strategies across various platforms such as Facebook, Twitter, Instagram, LinkedIn, Youtube, etc. They can create engaging content, manage social media accounts, and run paid advertising campaigns to increase brand awareness and engage with your target audience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfr">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefr" aria-expanded="false" aria-controls="collapsefr">
                      <span>04. </span>Content Marketing:
                    </button>
                  </h2>
                  <div id="collapsefr" className="accordion-collapse collapse" aria-labelledby="headingfr" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can assist with content creation and distribution strategies. They can help you develop relevant and valuable content, such as blog posts, articles, videos, and infographics, to attract and engage your audience. They can also optimize content for SEO and promote it through various channels.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfv">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefv" aria-expanded="false" aria-controls="collapsefv">
                      <span>05. </span> Email Marketing:
                    </button>
                  </h2>
                  <div id="collapsefv" className="accordion-collapse collapse" aria-labelledby="headingfv" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can help you build and manage effective email marketing campaigns. They can create compelling email templates, segment your email list, automate email sequences, and track campaign performance to nurture leads and drive conversions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsx">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesx" aria-expanded="false" aria-controls="collapsesx">
                      <span>06. </span> Conversion Rate Optimization (CRO): 
                    </button>
                  </h2>
                  <div id="collapsesx" className="accordion-collapse collapse" aria-labelledby="headingsx" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can analyze your website's user experience and conversion funnel to identify areas for improvement. They can conduct A/B testing, optimize landing pages, and implement strategies to increase the conversion rate of your website.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsvn">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesvn" aria-expanded="false" aria-controls="collapsesvn">
                      <span>07. </span> Analytics and Reporting: 
                    </button>
                  </h2>
                  <div id="collapsesvn" className="accordion-collapse collapse" aria-labelledby="headingsvn" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can provide data-driven insights by tracking and analyzing key metrics related to your digital marketing campaigns. They can generate regular reports to measure the effectiveness of your marketing efforts and make informed decisions for optimization.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                      <span>08. </span> Online Reputation Management (ORM):
                    </button>
                  </h2>
                  <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>DN Web Works</strong> can monitor and manage your online reputation. They can track mentions of your brand, respond to customer feedback and reviews, and implement strategies to build and maintain a positive brand image.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* faq accordion area end */}
          </div>
        </div>
        {/* <div class="col-lg-6">
          <div class="thumbnail-faq-four">
              <img src="assets/images/faq/02.png" alt="">
          </div>
        
  </div> */}
      </div>
    </div>
  </div>
  <section className="dnw-section-gap">
    <div className="container">
      <p className="disc">
        By choosing our Digital marketing solutions, you can leverage our expertise in improving your website's visibility, driving organic traffic, and achieving higher rankings in search engine results. Contact us today to discuss your Digital Marketing needs and let us help you achieve your online marketing objectives.
      </p>
    </div>
  </section>
</div>

    </>
  )
}
