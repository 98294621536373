import React from 'react'

export default function AddressCol() {
  return (
    <>
         <div className="col-xl-3 col-md-6 col-sm-12 col-12">
          <div className="footer-one-single-wized margin-left-65">
            <div className="wized-title">
              <h5 className="title">Get in Touch</h5>
              <img src="assets/images/footer/under-title.png" alt="dnweb_footer" />
            </div>
            <div className="wrapper">
              {/* single */}
              <div className="single">
                <label><i className="fas fa-map-marker-alt" /></label>
                <a href="https://goo.gl/maps/TKhGAniDaWuWjk8Q6">Near Amrit Dharam Kanda, Tajpur Road
                  Ludhiana,Punjab INDIA</a>
              </div>
              {/* single ENd */}
              {/* single */}
              <div className="single">
                <i className="fas fa-envelope" />
                <a href="mailto:dnwebworks@gmail.com">dnwebworks@gmail.com</a>
              </div>
              <div className="single">
                <i className="fas fa-envelope" />
                <a href="mailto:info@dnwebworks.com">info@dnwebworks.com</a>
              </div>
              {/* single ENd */}
              {/* single */}
              <div className="single">
                <i className="fas fa-phone" />
                <a href="tel:+917973490508">+91 797.349.0508</a>
              </div>
              {/* single ENd */}
              {/* single */}
              <div className="single">
                <i className="fas fa-globe" /><a href="https://dnwebworks.com/">www.dnwebworks.com</a>
              </div>
              {/* single ENd */}
            </div>
            <ul className="social-wrapper-one mt--35">
              <li><a href="https://www.facebook.com/dnwebworks" target="_blank"><i className="fab fa-facebook-f" /></a></li>
              <li><a href="https://twitter.com/dnwebworks" target="_blank"><i className="fab fa-twitter" /></a></li>
              <li><a href="https://www.instagram.com/dnwebworks/" target="_blank"><i className="fab fa-instagram" /></a></li>
              <li> <a href="https://api.whatsapp.com/send?phone=918288012437&text=Hello this is the starting message DN Web Works." target="_blank"><i className="fab fa-whatsapp" /></a></li>
              {/* <li><a class="mr--0" href="#"><i class="fab fa-linkedin-in"></i></a></li> */}
            </ul>
          </div>
        </div>
    </>
  )
}
